import { render, staticRenderFns } from "./GoldHeaderButton.vue?vue&type=template&id=0c8ea226&scoped=true"
import script from "./GoldHeaderButton.vue?vue&type=script&lang=js"
export * from "./GoldHeaderButton.vue?vue&type=script&lang=js"
import style0 from "./GoldHeaderButton.vue?vue&type=style&index=0&id=0c8ea226&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8ea226",
  null
  
)

export default component.exports