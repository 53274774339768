import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
const BACK_URL = process.env.VUE_APP_URL;

export default function useItemForm() {
    const products = ref([]);
    const productsLoading = ref(false)
    const lobbies = ref([]);
    const lobbiesLoading = ref(false)
    const productSelected = ref({});
    const lobbySelected = ref({});
    const promotions = ref([])
    const promotionsLoading = ref(false)
    const backURL = ref(BACK_URL);


    const resetSelections = () => {
        productSelected.value = {};
        lobbySelected.value = {};
    };

    return {
        products,
        productsLoading,
        lobbies,
        lobbiesLoading,
        productSelected,
        lobbySelected,
        resetSelections,
        promotions,
        promotionsLoading
    };
}