<script>
import {BButton, BFormInput} from "bootstrap-vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import goldStoreModule from "@/views/components/vlt-templates/Gold/store/goldStoreModule";
import {useToast} from "vue-toastification/composition";
import {computed, onMounted, ref} from "@vue/composition-api";
import {useRoute} from "@core/utils/utils";
import {MAIN_CONFIG} from "@/views/components/vlt-templates/Gold/config/GeneralEdit/mainConfig";
import formValidation from "@core/comp-functions/forms/form-validation";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "GeneralEditBackground",
  components: {ImageFieldForm, BButton, BFormInput},

  watch: {
    $route(to, {}) {
      this.layout = to.query.layout
      this.initBodyBgImage()
    }
  },

  setup() {
    const MODULE_VLT_STORE_NAME = "vlt-templates-store";
    const MODULE_GOLD_STORE_NAME = "gold-store";

    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(MODULE_GOLD_STORE_NAME))
      store.registerModule(MODULE_GOLD_STORE_NAME, goldStoreModule);

    const toast = useToast();
    const bodyBgImage = ref('');
    const loading = ref(false)
    const route = useRoute();
    const layout = ref(route.query.layout || 'horizontal')

    onMounted(() => {
      initBodyBgImage();
      updateLocalBodyBgImage()
      updatePreview();
    })

    const updateLocalBodyBgImage = () => {
      try {
        bodyBgImage.value = editTemplate.value.templateData[layout.value].body.href;
      } catch (e) {
        console.warn('APP TEMPLATE COLORS NOT EXIST OR INCORRECT FORMAT 🙈.');
      }
    };

    const initBodyBgImage = () => {
      bodyBgImage.value = MAIN_CONFIG[layout.value].body.href
    }

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const updatePreview = (value) => {
      const image = value ?  value.templateData[layout.value].body.href : bodyBgImage.value

      const updateTemplate = {...editTemplate.value}
      updateTemplate.templateData[layout.value].body.href = image
      store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updateTemplate);
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const updateTemplate = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MODULE_VLT_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: `templateData${layout}.body.href`
            }
        );

        showSuccessToast(toast, "Body", i18n.t("Background Updated"));
        updatePreview(response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_colors"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", `templateData.${layout}.body.href`);
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        bodyBgImage.value = data.files[data.files.length - 1]
        updatePreview();
      }
    };


    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onClearImage = () => {
      bodyBgImage.value = ''
    }

    return {
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      errorInLoadImage,
      resetForm,
      loading,
      layout,
      bodyBgImage,
      initBodyBgImage,
      onUploadedImage,
      onClearImage
    }

  }
}
</script>

<template>
  <b-tabs content-class="">
    <b-tab :title="$t('Body')" v-if="bodyBgImage !== undefined">
      <div class="list-group list-group-flush">
        <a
            class="list-group-item border-0 list-group-item-action d-flex gap-3"
            aria-current="true">
          <div class="gap-2 w-100 justify-content-between">
            <div>
              <h6 class="my-1">Background Image</h6>
            </div>

            <image-field-form
                :single-button="true"
                class="w-100"
                text="Logo"
                :value="bodyBgImage"
                path="vlt/headers"
                @uploadedImage="onUploadedImage($event)"
                @error="errorInLoadImage"
                preview="true"
            >
            </image-field-form>
            <div class="row" v-if="bodyBgImage.length">
              <div class="col">
                <img
                    style="max-width: 250px"
                    :src="bodyBgImage"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />

                <b-button class="close-btn" @click="onClearImage()" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>
            </div>
          </div>
        </a>

        <div class="row justify-content-end pt-4 pb-2">
          <div class="col-md-6">
            <b-button
                :disabled="loading"
                size="sm"
                class="w-100"
                variant="outline-primary"
                @click="resetData()"
            >
              {{ $t("buttons.cancel") }}
            </b-button>
          </div>

          <div class="col-md-6">
            <b-button
                :disabled="loading"
                class="w-100"
                @click="updateTemplate"
                size="sm"
                variant="primary"
            >
              Guardar
            </b-button
            >
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
  margin-top: 5px;
}

img {
  max-height: 25vh;
}
</style>