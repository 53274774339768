export const FOOTER = {
    vertical: {
        name: '',
        href: '',
        type: 'vertical',
        balance: {
            gradient_1_c: '',
            gradient_2_c: '',
            gradient_3_c: '',
            gradient_4_c: '',
            border_color: '',
            color: ''
        },
        games: []
    },

    horizontal: {
        type: 'horizontal',
        name: '',
        href: '',
        links: [],
    }
}