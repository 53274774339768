<template>
  <div
    class="size-selector d-flex justify-content-center align-items-center w-100 bg-transparent"
  >
    <b-button
      v-for="breakpoint in sizes"
      :key="breakpoint"
      size="sm"
      :variant="size === breakpoint ? 'success' : 'link'"
      @click="selectSize(breakpoint)"
    >
      <feather-icon :icon="`${capitalize(breakpoint)}Icon`" size="18" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: "TemplateSizeSelector",
  props: {
    sizes: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      size: "",
    };
  },
  methods: {
    selectSize(size) {
      this.size = size;
      this.$emit("selectSize", size);
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
  mounted() {
    this.selectSize("monitor");
  },
};
</script>

<style scoped></style>
