<template>
  <div class="gold-header-right">
    <button v-show="rightSection.soundButton.href.length" class="gold-header-right-btn">
      <img
        :src="rightSection.soundButton.href"
        alt="sound_button"
      />
    </button>

    <button  v-show="rightSection.languageButton.href"
             class="gold-header-right-btn">
      <img
        :src="rightSection.languageButton.href"
        alt="empty_button"
      />
    </button>
  </div>
</template>

<script>

export default {
  name: "GoldHeaderRight",
  props: {
    rightSection: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.gold-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding: 20px;

  &-btn {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
