<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import {computed, ref, onMounted} from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {svgs} from "@/views/components/whitelabel-templates/common/assets/svgs";
import formValidation from "@core/comp-functions/forms/form-validation";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import useItemForm from "@/views/components/whitelabel-templates/common/composables/useItemForm";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "ItemForm",
  components: {
    BFormSelect,
    ImageFieldForm,
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    iconList: {
      type: Array,
      default: () => []
    },
    template: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    sizeValidation: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    },
    item: {
      type: Object,
      default: () => ({
        name: "",
        src: "",
        primary_src: "",
        href: "",
        id: "",
        buttonType: "",
        position: "",
        children: [],
        title: "",
        status: "",
        svgData: "",
        hoverColor: "",
        sub_title: "",
        variant: "",
        text: "",
        borderRadius: 0,
      }),
    },
  },
  setup(props, {emit}) {
    const backURL = ref(BACK_URL);
    const formValid = ref(false);
    const toast = useToast();
    const options = ref(svgs);
    const itemCache = ref({});

    const {
      lobbySelected,
      lobbies,
      lobbiesLoading,
      products,
      productsLoading,
      productSelected,
      promotions,
      promotionsLoading
    } = useItemForm();


    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const variants = ref([
      {value: "casino", text: "Casino"},
      {value: "sports", text: "Sports"},
      {value: "racing", text: "Racing"},
    ]);

    const onCancel = () => {
      emit("reset", itemCache.value);
      props.settings.index = null
    };

    const linkButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };


    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        updateDynamicValue(image, path)
      }
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], props.item);
      if (target) {
        target[last] = value;
      }
    }



    const onClearImage = (path) => {
      props.item[path] = ''
    }

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onIconSelected = (icon) => {
      if (icon) props.item.svgData = icon.value;
      else props.item.svgData = ''
      emit("iconSelected");
    };

    const checkProduct = (product)=>{
      if (props.item.buttonType === 'product' && !product.promotion )
        getPromotionsByProductId(product.productId)
    }

    const getPromotionsByProductId = async (productId) => {
      try {
        promotionsLoading.value = true
        const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const {data} = await axios.get(`${backURL.value}/promotions`, {
          params: {
            whitelabelId,
            productId: productId
          }
        })
        promotions.value = data?.menus
      } catch (err){
        console.error("Error fetching data:", err);
      } finally {
        promotionsLoading.value = false
      }
    }

    const handleSelectPromotion = (promotionCode) => {
      let href = props.item.href;
      
      //We clean all promotions in the URL
      href = href.replace(/([?&])promotionCode=[^&]*/g, '').replace(/[?&]$/, '');

      // If there is a promotion code, we add it correctly
      if (promotionCode) {
        const separator = href.includes('?') ? '&' : '?';
        href += `${separator}promotionCode=${encodeURIComponent(promotionCode.code)}`;
      }

      props.item.href = href;
    };
    
    const fetchData = async () => {
      try {
        const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        productsLoading.value = true
        lobbiesLoading.value = true
        const [productsResponse, lobbiesResponse] = await Promise.all([
          axios.get(`${backURL.value}/clients/availProd/${clientId}`),
          axios.get(`${backURL.value}/lobbies`, {
            params: {
              whitelabelId,
              templateId: props.template._id
            }
          }),
        ]);
        products.value = productsResponse.data.map(({product}) => ({
          productId: product.productId,
          name: product.name,
        }));
        lobbies.value = lobbiesResponse.data.lobbys;
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        productsLoading.value = false
        lobbiesLoading.value = false
      }
    };

    const updateItemDetails = (val, type) => {
      if (val.position !== undefined) {
        props.item.position = val.position;
      } else if (type === "lobby") {
        lobbySelected.value = val;
        props.item.id = val._id;
        props.item.title = val.name;
        props.item.href = `/lobby?id=${val._id}`;
      } else if (type === "product") {
        productSelected.value = val;
        props.item.id = val.productId;
        props.item.title = val.name;
        props.item.href = `/product/${val.productId}`;
      }
    };

    const {refLinkFormObserver, getValidationState, resetForm} =
        formValidation(() => {
        });

    const lobby = computed({
      get: () => lobbySelected.value,
      set: (val) => {
        updateItemDetails(val, "lobby");
      },
    });

    const product = computed({
      get: () => productSelected.value,
      set: (val) => {
        updateItemDetails(val, "product");
        checkProduct(val)
      },
    });

    const saveCache = () => {
      itemCache.value = Object.assign({
        item: {...props.item},
        index: props.settings.index
      })
    }

    const filterIcons = () => {
       options.value = svgs.filter(icon => props.iconList.includes(icon.text));
    }

    onMounted(() => {
      saveCache()
      if (props.item.buttonType !== undefined) {
        fetchData()
      }

      if (props.iconList.length > 0) {
        filterIcons()
      }
    });

    return {
      formValid,
      onCancel,
      linkButtonHandler,
      onUploadedImage,
      refLinkFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      onClearImage,
      errorInLoadImage,
      onIconSelected,
      updateItemDetails,
      options,
      products,
      productsLoading,
      product,
      lobbies,
      lobbiesLoading,
      lobby,
      variants,
      promotions,
      promotionsLoading,
      handleSelectPromotion
    };
  },
};
</script>

<template>
  <validation-observer
      ref="refLinkFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item style="background: none" class="px-0">

        <b-row v-if="item.name !== undefined">
          <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.name')" label-for="name">
                <b-form-input
                    id="name"
                    v-model="item.name"
                    :state="getValidationState(validationContext)"
                    required/>
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.buttonType !== undefined">
          <b-col cols="12">
            <b-form-group :label="$t('button_type')" label-for="type">
              <v-select
                  v-model="item.buttonType"
                  label="text"
                  :options="['link', 'product', 'lobby']"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.buttonType === 'product'">
          <validation-provider
              #default="validationContext"
              name="product"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group
                  :label="$t('labels.products')"
                  label-for="products"
              >
                <v-select
                    v-model="product"
                    :options="products"
                    label="name"
                    :state="getValidationState(validationContext)"
                    required
                    :loading="productsLoading"
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>
        
        <b-row v-if="item.buttonType === 'product'">
          <validation-provider
              #default="validationContext"
              name="promotion"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group
                  :label="$t('promotions')"
                  label-for="promotion"
              >
                <v-select
                    v-model="item.promotion"
                    @input="handleSelectPromotion"
                    :options="promotions"
                    label="code"
                    :state="getValidationState(validationContext)"
                    required
                    :loading="promotionsLoading"
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.buttonType === 'lobby'">
          <validation-provider
              #default="validationContext"
              name="lobby"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('lobby')" label-for="lobby">
                <v-select
                    v-model="lobby"
                    :options="lobbies"
                    label="name"
                    track-by="name"
                    :state="getValidationState(validationContext)"
                    required
                    :loading="lobbiesLoading"
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              #default="validationContext"
              name="href"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.link')" label-for="href">
                <b-form-input
                    id="href"
                    v-model="item.href"
                    :state="getValidationState(validationContext)"
                    required
                    :disabled="item.buttonType === 'product' || item.buttonType === 'lobby'"/>
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.title !== undefined">
          <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('title')" label-for="title">
                <b-form-input
                    id="title"
                    v-model="item.title"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.hasOwnProperty('sub_title')">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="subtitle"
                rules="required"
            >
              <b-form-group label="subtitle" label-for="c-subtitle">
                <template #label>{{ $t("subtitle") }}</template>
                <b-form-input
                    id="c-subtitle"
                    type="text"
                    v-model="item.sub_title"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="item.src !== undefined">
          <div class="px-1 w-100">
            <div class="d-flex flex-column align-items-start mb-1">
              <span style="font-size: 12px">Primary Image</span>
              <b-badge pill size="sm" v-if="sizeValidation">{{ `(${size})`}}</b-badge>
            </div>
            <image-field-form
                :single-button="true"
                class="w-100"
                text="Images"
                :size-validation="sizeValidation ? size : null"
                :value="item.src"
                path="responsible_gaming"
                @uploadedImage="onUploadedImage($event, 'src')"
                @error="errorInLoadImage"
                :preview="false"/>

            <div class="row mt-1" v-if="item.src.length > 0">
                <div class="col">
                  <b-card
                      no-body
                      class="overflow-hidden image-card position-relative"
                  >
                    <b-card-img
                        :src="item.src"
                        alt="Uploaded Image"
                        class="rounded-0"
                    ></b-card-img>

                    <b-button
                        class="close-btn btn-close position-absolute position-right-0"
                        @click="onClearImage('src')"
                        variant="white"
                        size="sm"
                        aria-label="Close"
                        style="background: white; padding: 0"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                      >
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </b-button>
                  </b-card>
                </div>
            </div>
          </div>
        </b-row>

        <b-row v-if="item.primary_src !== undefined">
          <div class="px-1 w-100 mt-1">
            <span style="font-size: 12px" class="py-2 text-sm">Secondary Image</span>
            <image-field-form
                :single-button="true"
                class="w-100"
                text="Images"
                :value="item.primary_src"
                path="responsible_gaming"
                @uploadedImage="onUploadedImage($event, 'primary_src')"
                @error="errorInLoadImage"
                :preview="false"/>

            <div class="row mt-1" v-if="item.primary_src.length > 0">
              <div class="col">
                <b-card
                    no-body
                    class="overflow-hidden image-card position-relative"
                >
                  <b-card-img
                      :src="item.primary_src"
                      alt="Uploaded Image"
                      class="rounded-0"
                  ></b-card-img>

                  <b-button
                      class="close-btn btn-close position-absolute position-right-0"
                      @click="onClearImage('primary_src')"
                      variant="white"
                      size="sm"
                      aria-label="Close"
                      style="background: white; padding: 0"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                    >
                      <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </b-button>
                </b-card>
              </div>
            </div>

          </div>
        </b-row>

        <b-row v-if="item.svgData !== undefined">
          <b-col cols="12">
            <b-form-group label="Icon" label-for="icon">
              <b-input-group>
                <IconDropdown
                    :icons="options"
                    :selected-icon-prop="item.svgData"
                    @icon-selected="onIconSelected($event, item)"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.position !== undefined">
          <validation-provider
              rules="required"
              #default="validationContext"
              name="position"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group label-for="position">
                <template #label>position</template>
                <b-form-input
                    id="position"
                    type="number"
                    min="0"
                    required
                    v-model="item.position"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.hoverColor !== undefined">
          <b-col cols="12">
            <b-form-group
                :label="$t('labels.hovercolor')"
                label-for="h-backgroundColor"
            >
              <b-form-input
                  id="h-hovercolor"
                  type="color"
                  v-model="item.hoverColor"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.variant !== undefined">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="variant"
                rules="required"
            >
              <b-form-group label="variant" label-for="c-variant">
                <template #label>{{ $t("labels.variant") }}</template>

                <b-form-select
                    v-model="item.variant"
                    :options="variants"
                ></b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="item.hasOwnProperty('text')">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="text"
                rules="required"
            >
              <b-form-group label="text" label-for="cta_text">
                <template #label>{{ $t("labels.text") }}</template>

                <b-form-textarea
                    rows="6"
                    v-model="item.text"
                ></b-form-textarea>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        
        <b-row v-if="item.borderRadius !== undefined">
          <validation-provider
              rules="integer"
              #default="validationContext"
              name="borderRadius"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group label-for="borderRadius">
                <template #label>{{ $t("labels.borderRadius") }}</template>
                <b-form-input
                    id="position"
                    type="number"
                    min="0"
                    
                    v-model="item.borderRadius"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>
        
        <!--        <b-row>-->
        <!--          <validation-provider-->
        <!--              rules="required"-->
        <!--              #default="validationContext"-->
        <!--              name="content"-->
        <!--              class="w-100"-->
        <!--          >-->
        <!--            <b-col class="border-md-left" cols="12">-->
        <!--              <b-form-group label-for="text">-->
        <!--                <template #label>{{ $t("content") }}</template>-->
        <!--                <b-form-input-->
        <!--                    @input="onChangeItem()"-->
        <!--                    id="title"-->
        <!--                    required-->
        <!--                    type="text"-->
        <!--                    v-model="item.content">-->

        <!--                </b-form-input>-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--          </validation-provider>-->
        <!--        </b-row>-->


      </b-list-group-item>

      <div class="mt-2 d-flex flex-row justify-content-end" style="gap: 5px">
        <b-button
            :disabled="invalid"
            type="submit"
            variant="primary"
            size="sm"
        >
          {{
            settings.action === "edit"
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary" size="sm">{{
            $t("buttons.cancel")
          }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss"></style>
