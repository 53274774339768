<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UseIndex} from "@/views/components/vlt-templates/common/composables/useIndex";
import {useRoute} from "@core/utils/utils";
import vSelect from "vue-select";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import footerStoreModule from "./store/footerStoreModule";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {showErrorToast, showSuccessToast} from "@/views/components/vlt-templates/common/utils/showToast";
import {FOOTER} from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/models/footer";
import ItemForm from "@/views/components/vlt-templates/common/itemForm.vue";
import ItemList from "@/views/components/vlt-templates/common/itemList.vue";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {GAME} from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/models/game";
import {LINK} from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/models/link";


export default {
  name: "index",
  components: {
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    ItemForm,
    ItemList,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "footer",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const FOOTER_GOLD_STORE_MODULE_NAME = "gold-footer-store";

    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(FOOTER_GOLD_STORE_MODULE_NAME))
      store.registerModule(FOOTER_GOLD_STORE_MODULE_NAME, footerStoreModule);

    const footer = ref(null);
    const rBuilder = RequestBuilder();
    const debounceTimer = ref(null);
    const route = useRoute();
    const item = ref(null) // <== GAMES OR LINKS

    const uuidGen = UUIDGenerator();

    const {
      resetForm,
      toast,
      loading,
      getValidationState,
      editTemplate,
      refFormObserver,
      enableEditMode,
      enableAddMode,
      showForm,
      showList,
      settings,
      setIndex
    } = UseIndex();


    onMounted(() => {
      initFooter();
      initItem()
      const {itemsSelected} = props.options;
      if (itemsSelected) footer.value = itemsSelected;
      updatePreview();
    });

    const initItem = () => {
      const val = isVertical() ? GAME : LINK
      item.value = JSON.parse(JSON.stringify(val));
    }

    const isVertical = () => {
      return getLayout.value === 'vertical'
    }

    const getLayout = computed(() => {
      return route.query["layout"]
    })

    const isEditMode = computed(() => props.options.action === "edit");

    const initFooter = () => {
      footer.value = {...FOOTER[getLayout.value]}
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateFooter();
      } else {
        createFooter();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch(
            `${MODULE_VLT_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: `templateData.${getLayout.value}footer`
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createFooter = async () => {
      loading.value = true;
      footer.value.whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      footer.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${FOOTER_GOLD_STORE_MODULE_NAME}/addFooter`,
            rBuilder.clean(footer.value)
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_footer"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateFooter = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${FOOTER_GOLD_STORE_MODULE_NAME}/updateFooter`,
            {
              id: footer.value._id,
              footerData: rBuilder.clean(footer.value),
            }
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_footer"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updatePreview = (value) => {
      const updateTemplate = {...editTemplate.value}
      updateTemplate.templateData[getLayout.value].footer = value || footer.value
      store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updateTemplate);
    };

    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (path) {
          updateDynamicValue(image, path)
        }
        updatePreview();
      }
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], footer.value);
      if (target) {
        target[last] = value;
      }
    }

    const onClearImage = (path) => {
      updateDynamicValue('', path)
    }


    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit(`${MODULE_VLT_STORE_NAME}/RESET_EDIT_TEMPLATE`, `templateData.${getLayout.value}.footer`);
      store.commit(`${MODULE_VLT_STORE_NAME}/RE_RENDER_TEMPLATE`);
      resetAll();
      emit("reset");
    };


    const onColorChange = ({key, value}) => {
      const keys = key.split(".");
      let current = footer.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 500);
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      initItem();
      showList();
    };

    const loadItemFromCache = (val) => {
      footer.value[getActiveProp()][val.index] = val.item
    }

    const sortFooterItems = () => {
      const items = footer.value[getActiveProp()];
      items.sort((a, b) => Number(a.position) - Number(b.position));
      footer.value[getActiveProp()] = items;
    };


    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      footer.value[getActiveProp()].push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortFooterItems();
    };

    const updateItem = () => {
      footer.value[getActiveProp()] = footer.value[getActiveProp()].map((i) => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortFooterItems();
    };

    const onDeleteItem = (index) => {
      footer.value[getActiveProp()].splice(index, 1)
      updatePreview();
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      initItem();
      updatePreview();
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const getActiveProp = () => {
      return isVertical() ? 'games' : 'links'
    }

    const getFooterItems = () => {
      return isVertical() ? footer.value.games : footer.value.links
    }

    return {
      isEditMode,
      onSubmit,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      footer,
      onUploadedImage,
      errorInLoadImage,
      updatePreview,
      onClearImage,
      onColorChange,
      updateItem,
      resetAll,
      saveItem,
      onDeleteItem,
      onAddItem,
      onEditItem,
      isVertical,
      settings,
      item,
      getFooterItems
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="footer"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input id="s-name" type="text" v-model="footer.name"/>
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <!--NOT SHOW-->
              <b-form-group label="jackpot" label-for="h-jackpot">
                <template #label>{{ $t("labels.backgroundimage") }}</template>
                <image-field-form
                    :single-button="true"
                    :text="$t('labels.backgroundimage')"
                    class="w-100 mt-1"
                    @uploadedImage="onUploadedImage($event, 'href')"
                    @error="errorInLoadImage"
                    path="vlt/footers">
                </image-field-form>

                <div class="row" v-if="footer.href.length">
                  <div class="col">
                    <img
                        style="max-width: 100%"
                        :src="footer.href"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />

                    <b-button class="close-btn" @click="onClearImage('href')"
                              variant="outline-primary"
                              size="sm">
                      X
                    </b-button>
                  </div>
                </div>

                <template v-if="isVertical()">
                  <h4 class="pt-2">{{ $t("labels.balance") }}</h4>
                  <div class="accordion" role="tablist">
                    <b-list-group class="list-group-flush">
                      <b-list-group-item style="background: none" class="px-0">

                        <h6 v-b-toggle.accordion-1>{{ $t("Colors") }}</h6>
                        <b-collapse
                            id="accordion-1"
                            accordion="my-accordion"
                            role="tabpanel">

                          <div class="px-1 mt-1">
                            <validation-provider
                                #default="validationContext"
                                name="textColor"
                                class="w-100">

                              <b-form-group label="Gradient 1" label-for="gradient-1">
                                <b-form-input
                                    id="gradient-1"
                                    type="color"
                                    :value="footer.balance.gradient_1_c"
                                    @input="(newValue) => onColorChange({key: 'balance.gradient_1_c',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>

                              <b-form-group label="Gradient 2" label-for="gradient-2">
                                <b-form-input
                                    id="gradient-2"
                                    type="color"
                                    :value="footer.balance.gradient_2_c"
                                    @input="(newValue) => onColorChange({key: 'balance.gradient_2_c',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>


                              <b-form-group label="Gradient 3" label-for="gradient-3">
                                <b-form-input
                                    id="gradient-3"
                                    type="color"
                                    :value="footer.balance.gradient_3_c"
                                    @input="(newValue) => onColorChange({key: 'balance.gradient_3_c',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>

                              <b-form-group label="Gradient 4" label-for="gradient-4">
                                <b-form-input
                                    id="gradient-4"
                                    type="color"
                                    :value="footer.balance.gradient_4_c"
                                    @input="(newValue) => onColorChange({key: 'balance.gradient_4_c',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>

                              <b-form-group label="Border Color" label-for="border-color">
                                <b-form-input
                                    id="border-color"
                                    type="color"
                                    :value="footer.balance.border_color"
                                    @input="(newValue) => onColorChange({key: 'balance.border_color',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>

                              <b-form-group label="Color" label-for="color">
                                <b-form-input
                                    id="color"
                                    type="color"
                                    :value="footer.balance.color"
                                    @input="(newValue) => onColorChange({key: 'balance.color',value: newValue})"
                                    :state="getValidationState(validationContext)"/>
                                <small class="text-danger">
                                  {{ validationContext.errors[0] }}
                                </small>
                              </b-form-group>
                            </validation-provider>
                          </div>

                        </b-collapse>
                      </b-list-group-item>


                    </b-list-group>
                  </div>
                </template>
              </b-form-group>
            </b-col>
          </b-row>


        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="getFooterItems()"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div
            class="mt-4 d-flex flex-column"
            style="gap: 5px"
        >
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
h4 {
  color: #e5710a;
}

.close-btn {
  float: right;
  top: 5px;
  margin-top: 5px;
}
</style>
