<script>
import {
  BButton,
  BCard,
  BCol, BForm,
  BListGroup,
  BListGroupItem,
  BPopover,
  BRow, BTable,
} from "bootstrap-vue";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {mapState} from "vuex";
import TemplateNavMenu from "@/views/components/vlt-templates/common/TemplateNavMenu.vue";
import TemplateSizeSelector from "@/views/components/vlt-templates/common/TemplateSizeSelector.vue";
import UserProfile from "@/views/components/vlt-templates/common/UserProfile/UserProfile.vue";
import GoldCharger from "@/views/components/vlt-templates/Gold/GoldCharger.vue";
import GMGeneralEdit from "@/views/components/vlt-templates/Gold/config/GeneralEdit/GeneralEdit.vue";
import GMPageEdit from "@/views/components/vlt-templates/Gold/config/PageEdit/PageEdit.vue";

const MODULE_VLT_STORE_NAME = "vlt-templates";

export default {
  name: "index",
  components: {
    BForm,
    BTable,
    GoldCharger,
    UserProfile,
    TemplateNavMenu,
    TemplateSizeSelector,
    BPopover,
    BListGroupItem,
    BListGroup,
    BButton,
    BCol,
    BRow,
    BCard,
    GMPageEdit,
    GMGeneralEdit,
  },
  data() {
    return {
      size: "monitor",
      errorFetchingTemplate: false,
      menu: [
        {icon: "EditIcon", window: "g-edit", name: "General edit"},
        {icon: "ColumnsIcon", window: "p-edit", name: "Layout edit"}
      ],
      selectedMenuWindow: "",
      hoverEditComponent: "",
      closed: false,
      template: {
        templateData: []
      },
    };
  },
  computed: {
    ...mapState("vlt-templates", [
      "loadingFetchTemplate",
      "editTemplate",
      "reRenderTemplate",
    ]),

    getLayout() {
      return this.$route.query["layout"]
    },

    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },

  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel, oldWhitelabel) {
        if (newWhitelabel._id !== oldWhitelabel._id) this.fetchTemplateData();
      },
    },
  },

  methods: {
    onToggleSidebar(closed) {
      this.closed = closed;
    },

   async changeLayout(layout) {
      const currentRoute = this.$route;
      const targetRoute = {
        path: this.$route.path,
        query: {...this.$route.query, layout}
      };

      if (currentRoute.path !== targetRoute.path || JSON.stringify(currentRoute.query) !== JSON.stringify(targetRoute.query)) {
        await this.$router.push(targetRoute);
        store.commit(`${MODULE_VLT_STORE_NAME}/RE_RENDER_TEMPLATE`);
      }
    },

    handleMenuWindow(menu) {
      this.selectedMenuWindow = this.selectedMenuWindow === menu ? "" : menu;
    },

    async fetchTemplateData() {
      const id = this.$route.query.id;
      const params = {
        whitelabelId: this.getWhitelabelSelect._id,
      };
      this.errorFetchingTemplate = false;
      try {

        let {data: template} = await this.$store.dispatch(
            `${MODULE_VLT_STORE_NAME}/fetchTemplate`,
            {id, params}
        );

        this.$store.commit(
            `${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`,
            template
        );
        this.$store.commit(
            `${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE_RECOVERY`,
            JSON.parse(JSON.stringify(template))
        );
      } catch (e) {
        this.errorFetchingTemplate = true;
        console.error("Error fetching template:", e);
      } finally {
        //TODO DELETE FINALLY
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        //     this.template
        // );
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE_RECOVERY",
        //     JSON.parse(JSON.stringify(this.template))
        // );
        // this.errorFetchingTemplate = false;
      }
    },
  },

  created() {
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },

  async mounted() {
    await this.fetchTemplateData();
  },

  beforeCreate() {
    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
  },

  beforeDestroy() {
    if (store.hasModule(MODULE_VLT_STORE_NAME))
      store.unregisterModule(MODULE_VLT_STORE_NAME);
  },
};
</script>

<template>
  <div class="template-container">
    <!-- Loading status -->
    <div v-if="loadingFetchTemplate" class="loading-state">Cargando...</div>

    <!-- Error status -->
    <div v-else-if="errorFetchingTemplate" class="error-state">
      Error, Reintentar...
    </div>

    <div v-else-if="editTemplate" class="main-content d-flex flex-column">
      <!-- Size selector -->
      <template-size-selector
          :sizes="['smartphone', 'tablet', 'square', 'monitor']"
          @selectSize="(size) => (this.size = size)"
      />

      <b-tabs content-class="mt-1 tabs" justified>
        <b-tab @click="changeLayout('vertical')" title="Vertical" :active="getLayout === 'vertical'"></b-tab>
        <b-tab @click="changeLayout('horizontal')" title="Horizontal" :active="getLayout === 'horizontal'"></b-tab>
      </b-tabs>

      <section class="wrapper">
        <!-- Application loading component -->
        <gold-charger
            :layout="getLayout"
            :hover-edit-component="hoverEditComponent"
            :re-render="reRenderTemplate"
            :size="size"/>
        <!-- Navigation Container -->
        <TemplateNavMenu
            :menu="menu"
            :selected-menu-window="selectedMenuWindow"
            :closed="closed"
            @menu-window-selected="handleMenuWindow"
            @toggle-sidebar="onToggleSidebar"
        />

        <!-- Editing components -->
        <g-m-general-edit v-show="selectedMenuWindow === 'g-edit'"/>
        <g-m-page-edit v-show="selectedMenuWindow === 'p-edit'"/>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-content {
  min-height: 80vh;
  height: fit-content;
  gap: 5px;
}

.tabs {
  max-width: 200px;
  margin: auto;
}

.size-selector {
  padding: 10px;
}
</style>
