<template>
  <div class="app-header-left" :style="GOLD_HEADER_LEFT_STYLES">
    <div class="app-header-left-balance">
      Balance: <span ref="balanceNumber">0</span> USD
    </div>
    <div class="app-header-left-content">
      <gold-header-button
          :icon="leftSection.historyButton.href"
          :label="leftSection.historyButton.text"
      ></gold-header-button>

      <gold-header-button
          :icon="leftSection.withdrawButton.href"
          :label="leftSection.withdrawButton.text"
      ></gold-header-button>
    </div>
  </div>
</template>

<script>
import GoldHeaderButton from "@/views/components/vlt-templates/Gold/horizontal/common/header/GoldHeaderButton.vue";
import {ref} from "@vue/composition-api";

const MODULE_APP_STORE_NAME = "app-vlt-templates";

export default {
  name: "GoldHeaderLeft",
  props: {
    leftSection: {
      type: Object,
    },
  },
  components: {
    GoldHeaderButton,
  },

  setup() {

    const GOLD_HEADER_LEFT_STYLES = ref({
      'padding': '20px'
    })

    return {
      GOLD_HEADER_LEFT_STYLES,
    }
  }
};
</script>

<style scoped lang="scss">
.app-header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-balance {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--primaryColor);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-content {
    display: flex;
    gap: 20px;
  }
}
</style>
