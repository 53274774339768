<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import headerStoreModule from "./store/headerStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {UseIndex} from "@/views/components/vlt-templates/common/composables/useIndex";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useRoute} from "@core/utils/utils";
import {HEADER} from "@/views/components/vlt-templates/Gold/config/PageEdit/HeaderManagement/models/header";

const APP_URL = process.env.VUE_APP_URL;

export default {
  name: "Index",
  components: {
    ItemForm,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "header",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const HEADER_GOLD_STORE_MODULE_NAME = "gold-header-store";
    const header = ref(null);
    const rBuild = RequestBuilder();
    const route = useRoute();
    const rBuilder = RequestBuilder();

    // Register modules
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(HEADER_GOLD_STORE_MODULE_NAME))
      store.registerModule(HEADER_GOLD_STORE_MODULE_NAME, headerStoreModule);

    onMounted(() => {
      initHeader();
      const {itemsSelected} = props.options;
      if (itemsSelected) header.value = itemsSelected;
      updatePreview();
    });

    const isEditMode = computed(() => props.options.action === "edit");


    const getLayout = computed(() => {
      return route.query["layout"]
    })

    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeader();
      } else {
        createHeader();
      }
    };

    const {
      resetForm,
      toast,
      loading,
      getValidationState,
      editTemplate,
      refFormObserver,
    } = UseIndex();

    const updateTemplate = async () => {
      try {
        await store.dispatch("vlt-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: `templateData.${getLayout.value}.header`
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createHeader = async () => {
      loading.value = true;
      header.value.whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      header.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gold-header-store/addHeader",
            rBuild.clean(header.value)
        );

        showSuccessToast(toast, "Header", i18n.t("header_created"));

        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateHeader = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gold-header-store/updateHeader", {
          id: header.value._id,
          headerData: rBuilder.clean(header.value)
        });
        showSuccessToast(toast, "Header", i18n.t("header_updated"));

        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const initHeader = () => {
      header.value = {...HEADER[getLayout.value]}
    };


    const resetData = () => {
      store.commit(`${MODULE_VLT_STORE_NAME}/RESET_EDIT_TEMPLATE`, `templateData.${getLayout.value}.header`);
      store.commit(`${MODULE_VLT_STORE_NAME}/RE_RENDER_TEMPLATE`);
      emit("reset");
    };

    const updatePreview = (value) => {
      const updateTemplate = {...editTemplate.value}
      updateTemplate.templateData[getLayout.value].header = value || header.value
      store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updateTemplate);
    };


    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (path) {
          updateDynamicValue(image, path)
        }
        updatePreview();
      }
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], header.value);
      if (target) {
        target[last] = value;
      }
    }

    const onClearImage = (path) => {
      updateDynamicValue('', path)
    }

    const isVertical = () => {
      return getLayout.value === 'vertical';
    }


    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      header,
      loading,
      updatePreview,
      getLayout,
      onUploadedImage,
      onClearImage,
      errorInLoadImage,
      isVertical
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="header"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">

            <b-row>
              <b-col cols="12">
                <validation-provider
                    #default="validationContext"
                    name="name"
                    class="w-100"
                    rules="required">

                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="header.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"/>
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>

                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group label="jackpot" label-for="h-jackpot">
                  <template #label>{{ $t("jackpot") }}</template>
                  <image-field-form
                      :single-button="true"
                      class="w-100"
                      text="Logo"
                      :value="header.jackpot.href"
                      path="vlt/headers"
                      @uploadedImage="onUploadedImage($event, 'jackpot.href')"
                      @error="errorInLoadImage"
                      preview="true"
                  >
                  </image-field-form>

                  <div class="row" v-if="header.jackpot.href.length > 0">
                    <div class="col">
                      <img
                          style="max-width: 100%"
                          :src="header.jackpot.href"
                          alt="Uploaded Image"
                          class="mt-1 rounded"
                      />

                      <b-button class="close-btn" @click="onClearImage('jackpot.href')" variant="outline-primary"
                                size="sm">
                        X
                      </b-button>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <h4 class="pt-1">{{ $t("Right Section") }}</h4>

            <div class="accordion" role="tablist">
              <b-list-group class="list-group-flush mt-2">
                <b-list-group-item style="background: none" class="px-0">

                  <h6 v-b-toggle.accordion-1>{{ $t("Sound Button") }}</h6>

                  <b-collapse
                      id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel">

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="textColor"
                          class="w-100">

                        <b-form-group label="Color" label-for="auth-color">
                          <template #label>{{ $t("Image") }}</template>
                          <image-field-form
                              :single-button="true"
                              class="w-100"
                              text="Logo"
                              :value="header.rightSection.soundButton.href"
                              path="vlt/headers"
                              @uploadedImage="onUploadedImage($event, 'rightSection.soundButton.href')"
                              @error="errorInLoadImage"
                              preview="true"
                          >
                          </image-field-form>

                          <div class="row" v-if="header.rightSection.soundButton.href.length > 0">
                            <div class="col">
                              <img
                                  style="max-width: 100%"
                                  :src="header.rightSection.soundButton.href"
                                  alt="Uploaded Image"
                                  class="mt-1 rounded"
                              />

                              <b-button class="close-btn" @click="onClearImage('rightSection.soundButton.href')"
                                        variant="outline-primary"
                                        size="sm">
                                X
                              </b-button>
                            </div>
                          </div>
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <template v-if="isVertical()">
                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="text"
                            class="w-100">

                          <b-form-group label="Color" label-for="s-text">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="s-text"
                                type="text"
                                v-model="header.rightSection.soundButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="s-big"
                            class="w-100"
                        >
                          <b-form-group label="t-hide" label-for="s-big">
                            <template #label>{{ $t("big") }}</template>
                            <b-form-checkbox
                                class="px-0"
                                v-model="header.rightSection.soundButton.big"
                                switch
                                @change="updatePreview()"
                                size="md"
                            ></b-form-checkbox>
                          </b-form-group>
                        </validation-provider>
                      </div>

                    </template>
                  </b-collapse>
                </b-list-group-item>

                <b-list-group-item style="background: none" class="px-0">
                  <h6 v-b-toggle.accordion-2>{{ $t("Language Button") }}</h6>

                  <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel">

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="l-image"
                          class="w-100">

                        <b-form-group label="Color" label-for="l-image">
                          <template #label>{{ $t("Image") }}</template>
                          <image-field-form
                              :single-button="true"
                              class="w-100"
                              text="Image"
                              :value="header.rightSection.languageButton.href"
                              path="vlt/headers"
                              @uploadedImage="onUploadedImage($event, 'rightSection.languageButton.href')"
                              @error="errorInLoadImage"
                              preview="true"
                          >
                          </image-field-form>

                          <div class="row" v-if="header.rightSection.languageButton.href.length > 0">
                            <div class="col">
                              <img
                                  style="max-width: 100%"
                                  :src="header.rightSection.languageButton.href"
                                  alt="Uploaded Image"
                                  class="mt-1 rounded"
                              />

                              <b-button class="close-btn" @click="onClearImage('rightSection.languageButton.href')"
                                        variant="outline-primary"
                                        size="sm">
                                X
                              </b-button>
                            </div>
                          </div>
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <template v-if="isVertical()">
                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="l-text"
                            class="w-100">

                          <b-form-group label="Color" label-for="auth-bg-color">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="l-text"
                                type="text"
                                v-model="header.rightSection.languageButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="l-big"
                            class="w-100"
                        >
                          <b-form-group label="l-big" label-for="l-big">
                            <template #label>{{ $t("big") }}</template>
                            <b-form-checkbox
                                class="px-0"
                                v-model="header.rightSection.languageButton.big"
                                switch
                                @change="updatePreview()"
                                size="md"
                            ></b-form-checkbox>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </template>

                  </b-collapse>
                </b-list-group-item>

                <template v-if="isVertical()">

                  <b-list-group-item style="background: none" class="px-0">
                    <h6 v-b-toggle.accordion-3>{{ $t("History Button") }}</h6>

                    <b-collapse
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel">

                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="h-image"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-image">
                            <template #label>{{ $t("Image") }}</template>
                            <image-field-form
                                :single-button="true"
                                class="w-100"
                                text="Image"
                                :value="header.rightSection.historyButton.href"
                                path="vlt/headers"
                                @uploadedImage="onUploadedImage($event, 'rightSection.historyButton.href')"
                                @error="errorInLoadImage"
                                preview="true">
                            </image-field-form>

                            <div class="row" v-if="header.rightSection.historyButton.href.length > 0">
                              <div class="col">
                                <img
                                    style="max-width: 100%; max-height: 150px"
                                    :src="header.rightSection.historyButton.href"
                                    alt="Uploaded Image"
                                    class="mt-1 rounded"
                                />

                                <b-button class="close-btn" @click="onClearImage('rightSection.historyButton.href')"
                                          variant="outline-primary"
                                          size="sm">
                                  X
                                </b-button>
                              </div>
                            </div>

                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="text"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-text">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="h-text"
                                type="text"
                                v-model="header.rightSection.historyButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="h-big"
                            class="w-100"
                        >
                          <b-form-group label="t-hide" label-for="h-big">
                            <template #label>{{ $t("big") }}</template>
                            <b-form-checkbox
                                class="px-0"
                                v-model="header.rightSection.historyButton.big"
                                switch
                                @change="updatePreview()"
                                size="md"
                            ></b-form-checkbox>
                          </b-form-group>
                        </validation-provider>
                      </div>


                    </b-collapse>
                  </b-list-group-item>

                  <b-list-group-item style="background: none" class="px-0">
                    <h6 v-b-toggle.accordion-4>{{ $t("Withdraw Button") }}</h6>

                    <b-collapse
                        id="accordion-4"
                        accordion="my-accordion"
                        role="tabpanel">

                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="w-image"
                            class="w-100">

                          <b-form-group label="Color" label-for="w-image">
                            <template #label>{{ $t("Image") }}</template>
                            <image-field-form
                                :single-button="true"
                                class="w-100"
                                text="Image"
                                :value="header.rightSection.withdrawButton.href"
                                path="vlt/headers"
                                @uploadedImage="onUploadedImage($event, 'rightSection.withdrawButton.href')"
                                @error="errorInLoadImage"
                                preview="true"
                            >
                            </image-field-form>

                            <div class="row" v-if="header.rightSection.withdrawButton.href.length > 0">
                              <div class="col">
                                <img
                                    style="max-width: 100%;  max-height: 150px"
                                    :src="header.rightSection.withdrawButton.href"
                                    alt="Uploaded Image"
                                    class="mt-1 rounded"
                                />

                                <b-button class="close-btn" @click="onClearImage('rightSection.withdrawButton.href')"
                                          variant="outline-primary"
                                          size="sm">
                                  X
                                </b-button>
                              </div>
                            </div>


                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="text"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-text">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="h-text"
                                type="text"
                                v-model="header.rightSection.withdrawButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="h-big"
                            class="w-100"
                        >
                          <b-form-group label="w-big" label-for="w-big">
                            <template #label>{{ $t("big") }}</template>
                            <b-form-checkbox
                                class="px-0"
                                v-model="header.rightSection.withdrawButton.big"
                                switch
                                @change="updatePreview()"
                                size="md"
                            ></b-form-checkbox>
                          </b-form-group>
                        </validation-provider>
                      </div>


                    </b-collapse>
                  </b-list-group-item>

                </template>
              </b-list-group>
            </div>

            <!--LEFT SECTION-->


            <template v-if="!isVertical()">
              <h4 class="pt-1">{{ $t("Left Section") }}</h4>

              <div class="accordion" role="tablist">
                <b-list-group class="list-group-flush mt-2">

                  <b-list-group-item style="background: none" class="px-0">
                    <h6 v-b-toggle.accordion-3>{{ $t("History Button") }}</h6>

                    <b-collapse
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel">

                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="h-image"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-image">
                            <template #label>{{ $t("Image") }}</template>
                            <image-field-form
                                :single-button="true"
                                class="w-100"
                                text="Image"
                                :value="header.leftSection.historyButton.href"
                                path="vlt/headers"
                                @uploadedImage="onUploadedImage($event, 'leftSection.historyButton.href')"
                                @error="errorInLoadImage"
                                preview="true">
                            </image-field-form>

                            <div class="row" v-if="header.leftSection.historyButton.href.length > 0">
                              <div class="col">
                                <img
                                    style="max-width: 100%"
                                    :src="header.leftSection.historyButton.href"
                                    alt="Uploaded Image"
                                    class="mt-1 rounded"
                                />

                                <b-button class="close-btn"
                                          @click="onClearImage('leftSection.historyButton.href')"
                                          variant="outline-primary"
                                          size="sm">X
                                </b-button>
                              </div>
                            </div>

                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="text"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-text">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="h-text"
                                type="text"
                                v-model="header.leftSection.historyButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                    </b-collapse>
                  </b-list-group-item>

                  <b-list-group-item style="background: none" class="px-0">
                    <h6 v-b-toggle.accordion-4>{{ $t("Withdraw Button") }}</h6>

                    <b-collapse
                        id="accordion-4"
                        accordion="my-accordion"
                        role="tabpanel">

                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="w-image"
                            class="w-100">

                          <b-form-group label="Color" label-for="w-image">
                            <template #label>{{ $t("Image") }}</template>
                            <image-field-form
                                :single-button="true"
                                class="w-100"
                                text="Image"
                                :value="header.leftSection.withdrawButton.href"
                                path="vlt/headers"
                                @uploadedImage="onUploadedImage($event, 'leftSection.withdrawButton.href')"
                                @error="errorInLoadImage"
                                preview="true"
                            >
                            </image-field-form>

                            <div class="row" v-if="header.leftSection.withdrawButton.href.length > 0">
                              <div class="col">
                                <img
                                    style="max-width: 100%"
                                    :src="header.leftSection.withdrawButton.href"
                                    alt="Uploaded Image"
                                    class="mt-1 rounded"
                                />

                                <b-button class="close-btn" @click="onClearImage('leftSection.withdrawButton.href')"
                                          variant="outline-primary"
                                          size="sm">
                                  X
                                </b-button>
                              </div>
                            </div>


                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>


                      <div class="px-1 mt-1">
                        <validation-provider
                            #default="validationContext"
                            name="text"
                            class="w-100">

                          <b-form-group label="Color" label-for="h-text">
                            <template #label>{{ $t("labels.text") }}</template>
                            <b-form-input
                                id="h-text"
                                type="text"
                                v-model="header.leftSection.withdrawButton.text"
                                @input="updatePreview()"
                                :state="getValidationState(validationContext)"/>
                            <small class="text-danger">
                              {{ validationContext.errors[0] }}
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </b-collapse>
                  </b-list-group-item>

                </b-list-group>
              </div>
            </template>

          </b-list-group-item>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
h4 {
  color: #e5710a;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 15px;
}
</style>
