<template>
  <div v-show="jackpot.href.length"
       class="gold-header-center"
       :style="GOLD_HEADER_CENTER_STYLES">
    <img
        :src="jackpot.href"
        alt="jackpot_balance"
    />
    <div class="gold-header-center-value" :style="GOLD_HEADER_CENTER_VALUE_STYLES">
      <span>{{ displayValue }}</span>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {computed, ref} from "@vue/composition-api";

export default {
  name: "GoldHeaderCenter",
  props: {
    jackpot: {
      type: Object,
    },
  },
  data() {
    return {
      jackpotBalance: null,
      randomInterval: null,
      displayValue: "0.00",
    };
  },

  setup() {
    const MODULE_APP_STORE_NAME = "app-vlt-templates";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, vltTemplatesModule);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const GOLD_HEADER_CENTER_STYLES = ref({
      'text-align': 'center'
    })

    const GOLD_HEADER_CENTER_VALUE_STYLES = ref({
      'left': '50%',
      'transform': 'translate(-50%, -50%)',
    })


    return {
      editTemplate,
      GOLD_HEADER_CENTER_STYLES,
      GOLD_HEADER_CENTER_VALUE_STYLES
    }
  },
};
</script>

<style scoped lang="scss">
.gold-header-center {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
  font-weight: 700;
  text-align: center;
  font-size: 60px;
  position: relative;

  &-value {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;
  }
}
</style>
