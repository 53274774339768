<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  data() {
    return {
      providerSelected: null,
      providersActiveIndex: 0,
      providers: [
        "pragmaticplay",
        "pgsoft",
        "rubyplay",
        "redtiger",
        "3oaks",
        "belatra",
        "btg",
        "playson",
        "nolimit",
        "mr.slotty",
        "amusnet",
        "pascal",
      ],
      gamesActiveIndex: 0,
      games: [
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
        {
          id: 1,
          img: "/img/game.png",
          title: "Game 1",
          link: "/game1",
        },
      ]
    };
  },
  watch: {
    providerSelected() {
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  methods: {
    providerNext() {
      this.$refs.appproviders.swiper.slideNext();
    },
    providerPrev() {
      this.$refs.appproviders.swiper.slidePrev();
    },
    gameNext() {
      this.$refs.gamesref.swiper.slideNext();
    },
    gamePrev() {
      this.$refs.gamesref.swiper.slidePrev();
    },
    initSwiper() {
      new Swiper(this.$refs.appproviders, {
        init: true,
        slidesPerView: 5,
        slidesPerGroup: 5,
        grabCursor: true,

        on: {
          slideChange: swiper => {
            this.providersActiveIndex = swiper.realIndex;
          },
        },
      });
      new Swiper(this.$refs.gamesref, {
        init: true,
        slidesPerView: 1,
        grabCursor: true,

        on: {
          slideChange: swiper => {
            this.gamesActiveIndex = swiper.realIndex;
          },
        },
      });
    },
  },
  mounted() {
    this.initSwiper()
  },

  setup() {
    const GAME_STYLES = {
      'aspect-ratio': '15 / 11'
    }

    const GAME_IMAGE_STYLES = {
      'width': '100%',
      'height': '100%'
    }

    const APP_SWIPER_ITEM_STYLES = {
      'padding': '20px'
    }

    const APP_SWIPER_ITEM_PROVIDER_STYLES = {
      'left': '20px',
      'width': 'calc(100% - calc(20px * 2))',
      'height': 'calc(100% - 20px)'
    }

    const SWIPER_BUTTON_STYLES = {
      'background': 'rgb(255 204 0 / 72%) !important',
      'width': '100px !important',
      'height': '100px !important',
      'border-radius': '50px !important',
      'border': '8px solid #2f0d0d !important',
      'color': '#000 !important',
      'font-weight': '700 !important',
    }

    const SWIPER_BUTTON_RIGHT_STYLES = {
      'right': 'var(--swiper-navigation-sides-offset,10px)',
      'left': 'auto',
    }

    return {
      GAME_STYLES,
      GAME_IMAGE_STYLES,
      APP_SWIPER_ITEM_STYLES,
      APP_SWIPER_ITEM_PROVIDER_STYLES,
      SWIPER_BUTTON_STYLES,
      SWIPER_BUTTON_RIGHT_STYLES
    }
  }
};
</script>

<template>
  <!-- view games -->
  <div class="games" v-if="providerSelected">
    <!-- header -->
    <div class="games-header" style="left: 0; transform: translateY(-10px);">
      <div class="games-header-title">
        {{ providerSelected }}
      </div>

      <button @click="providerSelected = null" class="h-12">
        <img src="/img/home.gif" class="h-full" alt="">
      </button>
    </div>

    <!-- swiper -->
    <div ref="gamesref" class="w-full swiper">
      <div class="swiper-wrapper">
        <div
            v-for="index in Math.ceil(games.length / 21)"
            :key="`game-group-${index}`"
            class="swiper-slide">
          <div class="grid grid-cols-7 gap-2 px-20">
            <template v-for="game in 21">
              <a class="game"
                 :style="GAME_STYLES"
                 v-if="(((index - 1) * 21) + game - 1) < games.length"
                 :key="`game-${game}`"
                 :href="games[((index - 1) * 21) + game - 1].link"
                 :title="games[((index - 1) * 21) + game - 1].title">
                <img class="game-img" :style="GAME_IMAGE_STYLES" loading="lazy"
                     :src="games[((index - 1) * 21) + game - 1].img" alt=""/>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- view providers -->
  <div ref="appproviders" class="swiper app-swiper" v-else>
    <div class="swiper-wrapper">
      <div
          v-for="(item, index) in providers"
          :key="index"
          class="swiper-slide app-swiper-item"
          :style="APP_SWIPER_ITEM_STYLES"
          @click="providerSelected = item">
        <img class="app-swiper-item-img" :src="`/img/providers/${item}.gif`" alt=""/>
        <img class="app-swiper-item-provider" :style="APP_SWIPER_ITEM_PROVIDER_STYLES"
             :src="`/img/providers/${item}-t.gif`" alt=""/>
      </div>
    </div>
    <div :style="[SWIPER_BUTTON_STYLES, SWIPER_BUTTON_RIGHT_STYLES]" class="swiper-button-next app-swiper-next"
         @click="providerNext"></div>
    <div :style="SWIPER_BUTTON_STYLES" class="swiper-button-prev app-swiper-prev" @click="providerPrev"></div>
  </div>
</template>


<style scoped lang="scss">
$space: 20px;

.games {
  display: flex;
  flex-direction: column;
  gap: $space;
  position: relative;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $space *5;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    transform: translateY(-10px);

    &-title {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: capitalize;
    }
  }


}

.game {
  aspect-ratio: 15 / 11;

  &-img {
    width: 100%;
    height: 100%;
  }
}


.app-swiper {
  width: 100%;

  &-item {
    padding: 0 $space;
    position: relative;

    &-img {
      width: 100%;
    }

    &-provider {
      position: absolute;
      top: $space;
      left: $space;
      width: calc(100% - #{$space * 2});
      height: calc(100% - #{$space});
      z-index: 1;
    }
  }

  &-next,
  &-prev {
    background: rgb(255 204 0 / 72%) !important;
    width: 100px !important;
    height: 100px !important;
    border-radius: 50px !important;
    border: 8px solid #2f0d0d !important;
    color: #000 !important;
    font-weight: 700 !important;
  }

  .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
}


</style>