<template>
  <div>
    <b-sidebar
        :visible="sideBar.visible"
        @hidden="close()"
        :title="sideBar.title"
        right
    >
      <div class="px-1 py-2">
        <b-overlay
            :show="loading"
            variant="transparent"
            rounded
            spinner-variant="primary"
            spinner-type="grow"
            blur="50px"
        >
          <component
              :is="getSideBarComponent()"
              @reset="close()"
              @created="close()"
              @updated="close()"
              v-if="sideBar.type"
              :options="sideBar"
          ></component>
        </b-overlay>
      </div>
    </b-sidebar>

    <div class="card template-card">
      <b-overlay
          :show="loading"
          variant="transparent"
          rounded
          spinner-variant="primary"
          spinner-type="grow"
          blur="50px"
      >
        <ul
            class="d-flex nav flex-column ds-btn"
            style="padding: 0; list-style: none"
        >
          <li
              v-for="(data, index) in edit"
              :key="index"
              class="w-100 nav-item"
          >
            <div
                class="w-100 mb-1 text-left cursor-pointer"
                style="background: none !important; border: none !important"
                @click="expandMenu(index)"
            >
              <span>{{ $t(data.name) }}</span>
              <span class="float-right">
                <feather-icon
                    :icon="data.collapsed ? 'ChevronUpIcon' : 'ChevronRightIcon'"
                    size="16"
                />
              </span>
            </div>

            <b-collapse :id="'collapse-' + index" v-model="data.collapsed">
              <component
                  v-if="data.collapsed"
                  :is="getComponentForName(data.name)"
                  class="mb-1"
                  @updateOrCreate="
                  onUpdateOrCreate(
                    $event,
                    getTypeForName(data.name),
                    $t(data.name)
                  )
                "
              ></component>
            </b-collapse>
          </li>
        </ul>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {BOverlay} from "bootstrap-vue";
import PEditFooter from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/index.vue";
import PEditHeader from "@/views/components/vlt-templates/Gold/config/PageEdit/HeaderManagement/index.vue";
import PEditLobby from "@/views/components/vlt-templates/Gold/config/PageEdit/LobbyManagement/index.vue";
import HeaderList from "@/views/components/vlt-templates/Gold/config/PageEdit/HeaderManagement/headerList.vue";
import LobbyList from "@/views/components/vlt-templates/Gold/config/PageEdit/LobbyManagement/lobbyList.vue";
import FooterList from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/footerList.vue";
import CoinsAnimationList from "@/views/components/vlt-templates/Gold/config/PageEdit/CoinsAnimationManagement/coinsAnimationList.vue";
import PEditCoinsAnimation from "@/views/components/vlt-templates/Gold/config/PageEdit/CoinsAnimationManagement/index.vue";
export default {
  name: "AppPageEdit",
  components: {
    BOverlay,
    HeaderList,
    LobbyList,
    FooterList,
    PEditHeader,
    PEditLobby,
    PEditFooter,
    CoinsAnimationList,
    PEditCoinsAnimation
  },

  data() {
    return {
      sideBar: {
        visible: false,
        itemsSelected: null,
        type: "",
        title: "",
        action: "",
      },
      edit: [
        {name: "Header", collapsed: false},
        {name: "Lobby", collapsed: false},
        {name: "CoinsAnimation", collapsed: false},
        {name: "Footer", collapsed: false},
      ],
    };
  },

  methods: {
    onUpdateOrCreate(value, type, title) {
      const currentlyExpanded = this.edit.findIndex((item) => item.collapsed);
      if (currentlyExpanded !== -1) {
        this.edit[currentlyExpanded].collapsed = false;
      }

      this.sideBar = {
        visible: true,
        itemsSelected: value.item,
        type,
        title,
        action: value.action,
      };

    },

    close() {
      this.sideBar = {
        visible: false,
        itemsSelected: null,
        type: null,
        title: null,
        action: null,
      };
    },

    expandMenu(index) {
      this.close();
      const currentlyExpanded = this.edit.findIndex((item) => item.collapsed);
      if (currentlyExpanded !== -1 && currentlyExpanded !== index) {
        this.edit[currentlyExpanded].collapsed = false;
      }
      this.edit[index].collapsed = !this.edit[index].collapsed;
    },

    getComponentForName(name) {
      const componentMap = {
        Header: "header-list",
        Lobby: "lobby-list",
        CoinsAnimation: "coins-animation-list",
        Footer: "footer-list",
      };
      return componentMap[name] || null;
    },

    getTypeForName(name) {
      return name.toLowerCase();
    },

    getSideBarComponent() {
      const componentMap = {
        header: PEditHeader,
        lobby: PEditLobby,
        coinsanimation: PEditCoinsAnimation,
        footer: PEditFooter
      };
      return componentMap[this.sideBar.type] || null;
    },
  },

  computed: {
    loading() {
      return store.state["app-whitelabel-templates"].loadingUpdate;
    },
  },
};
</script>

<style scoped lang="scss">
.template-card {
  min-width: 200px;
  max-height: 70vh;
  overflow-y: auto;
  min-height: 200px;
  position: absolute;
  background: white;
  top: 15px;
  left: 70px;
  padding: 12px;
  border-radius: 10px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.dark-layout {
  .card {
    background: #283046 !important;
  }

  .nav-link {
    color: #fff !important;
  }
}

.slide-x-enter-active,
.slide-x-leave-active {
  transition: all 0.25s ease;
}

.slide-x-enter {
  transform: translateX(-15%);
}

.slide-x-leave-to {
  opacity: 0;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.2s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 1000px;
}
</style>
