export const MAIN_CONFIG = {
    vertical: {
        body: {
          href: ''
        },
        main: {
            colors: {
                primaryColor: '',
                textColor: '',
                bodyColor: '',
                backgroundColor: ''
            }
        }
    },
    horizontal: {
        body: {
            href: ''
        },
        main: {
            background: '',
            colors: {
                primaryColor: '',
                textColor: '',
                bodyColor: '',
                backgroundColor: ''
            }
        }
    },
}