export const COINS_ANIMATION = {
    vertical: {
        name: '',
        href_video: '',
        type: 'vertical'
    },
    horizontal: {
        name: '',
        href: '',
        type: 'horizontal'
    }
}