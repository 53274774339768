import axios from '@/libs/axios'

const URL = `${process.env.VUE_APP_URL}/vlt-templates-v2/gold`
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchFooters(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/footer`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchFooter(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/footer/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addFooter(ctx, footerData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${URL}/footer`, footerData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateFooter(ctx, {id, footerData}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${URL}/footer/${id}`, footerData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchFooterImage(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/footer/files/${id}?path=footer`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeFooter(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${URL}/footer/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
