import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {computed, onUnmounted, ref, watch} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import {
    showErrorToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {useRoute} from "@core/utils/utils";
import headerStoreModule
    from "@/views/components/vlt-templates/Gold/config/PageEdit/HeaderManagement/store/headerStoreModule";

export default function useHeadersList() {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const HEADER_GOLD_STORE_MODULE_NAME = "gold-header";

    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
        store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);


    if (!store.hasModule(HEADER_GOLD_STORE_MODULE_NAME))
        store.registerModule(HEADER_GOLD_STORE_MODULE_NAME, headerStoreModule);

    onUnmounted(() => {
        if (store.hasModule(HEADER_GOLD_STORE_MODULE_NAME))
            store.unregisterModule(HEADER_GOLD_STORE_MODULE_NAME);
    });

    const toast = useToast();
    const refHeaderListTable = ref(null);
    const loading = ref(false);
    const perPage = ref(10);
    const totalHeaders = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);
    const recordDeleted = ref(false);
    const layout = ref('horizontal')

    const route = useRoute();

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const dataMeta = computed(() => {
        const localItemsCount = refHeaderListTable.value?.localItems.length || 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalHeaders.value,
        };
    });

    const refetchData = () => {
        refHeaderListTable.value?.refresh();
    };

    const isSelected = (id) => {
        return editTemplate.value.templateData[layout.value]?.header._id === id
    };

    const findSelected = (id) => {
        return (
            refHeaderListTable.value?.localItems.find((item) => item._id === id) ||
            null
        );
    };

    const selectHeader = async (id) => {
        const selected = findSelected(id);

        if (!selected) return;


        const updatedTemplate = {
            ...editTemplate.value,
        };

        updatedTemplate.templateData[layout.value].header = selected

        store.commit('vlt-templates/SET_EDIT_TEMPLATE', updatedTemplate);

        try {
            await store.dispatch("vlt-templates/updateTemplate", {
                template: updatedTemplate,
                toast,
                path: `templateData.${layout}.header`
            });
        } catch (error) {
            showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
        }
    };

    const fetchHeaders = (ctx, callback) => {
        loading.value = true;

        store
            .dispatch(`${HEADER_GOLD_STORE_MODULE_NAME}/fetchHeaders`, {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
                type: layout.value,
                templateId: editTemplate.value._id
            })
            .then((response) => {
                loading.value = false;
                const {data, total} = response.data;
                callback(data);
                totalHeaders.value = total;
            })
            .catch((error) => {
                loading.value = false;
                showErrorToast(
                    toast,
                    "Error fetching headers list",
                    axiosErrorHandle(error)
                );
            });
    };

    watch([currentPage, perPage, searchQuery, recordDeleted], () => {
        refetchData();
    });


    return {
        isSelected,
        fetchHeaders,
        perPage,
        currentPage,
        totalHeaders,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refHeaderListTable,
        refetchData,
        recordDeleted,
        loading,
        layout,
        route,
        selectHeader,
    };
}
