<template>
  <button
    :class="['gold-header-left-btn', customClass]"
    @click="handleClick"
    :disabled="disabled"
  >
    <img v-if="icon" :src="icon" :alt="label" :class="iconClass" />
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "HeaderButton",
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    customClass: {
      type: [String, Array],
      default: "",
    },
    iconClass: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gold-header-left-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  font-size: medium;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--textColor);
  background: none;
  border: none;
  outline: none;

  img {
    height: 60px;
    transition: 0.3s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
