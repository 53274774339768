<script>
import {BButton, BFormInput} from "bootstrap-vue";
import {computed, onBeforeUnmount, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import {useToast} from "vue-toastification/composition";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import goldStoreModule from "@/views/components/vlt-templates/Gold/store/goldStoreModule";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useRoute} from "@core/utils/utils";
import {MAIN_CONFIG} from "@/views/components/vlt-templates/Gold/config/GeneralEdit/mainConfig";

export default {
  name: "GEditColors",
  components: {BButton, BFormInput},

  watch: {
    $route(to, {}) {
      this.layout = to.query.layout
      this.initMainColors()
    }
  },

  setup() {
    const MODULE_VLT_STORE_NAME = "vlt-templates-store";
    const MODULE_GOLD_STORE_NAME = "gold-store";

    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(MODULE_GOLD_STORE_NAME))
      store.registerModule(MODULE_GOLD_STORE_NAME, goldStoreModule);

    const toast = useToast();
    const debounceTimer = ref(null);
    const mainColors = ref(null);
    const loading = ref(false)
    const route = useRoute();
    const layout = ref(route.query.layout || 'horizontal')

    const initMainColors = () => {
      mainColors.value = MAIN_CONFIG[layout.value].main.colors
    }

    const updatePreview = (value) => {
      const colors = value ?  value.templateData[layout.value].main.colors : mainColors.value

      const updateTemplate = {...editTemplate.value}
      updateTemplate.templateData[layout.value].main.colors = colors
      store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updateTemplate);
    };


    // const changePreview = (value) => {
    //   store.commit(`${MODULE_GOLD_STORE_NAME}/SWITCH_PREVIEW`, value);
    // };

    // onBeforeUnmount(() => {
    //   if (debounceTimer.value) {
    //     clearTimeout(debounceTimer.value);
    //   }
    //   changePreview("general");
    // });

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", `templateData.${layout.value}.main.colors`);
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      updateLocalColors()
    };

    const updateLocalColors = () => {
      try {
        mainColors.value = editTemplate.value.templateData[layout.value].main.colors;
      } catch (e) {
        console.warn('APP TEMPLATE COLORS NOT EXIST OR INCORRECT FORMAT 🙈.');
      }
    };

    onMounted(() => {
      initMainColors()
      updateLocalColors();
    });


    const onColorChange = (value, key) => {
      mainColors.value[key] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000); // 5000 ms = 5 segundos
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );


    const updateTemplate = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MODULE_VLT_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: `templateData${layout}.main.colors`
            }
        );

        showSuccessToast(toast, "Colors", i18n.t("colors_updated"));
        updatePreview(response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_colors"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };


    return {
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      mainColors,
      loading,
      layout,
      onColorChange,
      initMainColors
    }
  },

}
;
</script>

<template>
  <div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('Main')" v-if="mainColors">
          <div class="list-group list-group-flush">
            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true">
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="mainColors.primaryColor"
                      @input="
                      (newValue) => onColorChange(newValue, 'primaryColor')"/>
                </span>
              </div>
            </a>

            <a class="list-group-item border-0 list-group-item-action d-flex gap-3"
               aria-current="true">
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Text Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="mainColors.textColor"
                      @input="(newValue) => onColorChange(newValue, 'textColor')"/>
                </span>
              </div>
            </a>

            <a class="list-group-item border-0 list-group-item-action d-flex gap-3" aria-current="true">

              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Body Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="mainColors.bodyColor"
                      @input="(newValue) => onColorChange(newValue, 'bodyColor')"/>
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="mainColors.backgroundColor"
                      @input="(newValue) => onColorChange(newValue, 'backgroundColor')"/>
                </span>
              </div>
            </a>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <div class="row justify-content-end pt-4 pb-2">
      <div class="col-md-6">
        <b-button
            :disabled="loading"
            size="sm"
            class="w-100"
            variant="outline-primary"
            @click="resetData()"
        >
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>

      <div class="col-md-6">
        <b-button
            :disabled="loading"
            class="w-100"
            @click="updateTemplate"
            size="sm"
            variant="primary"
        >
          Guardar
        </b-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>
