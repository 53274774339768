<template>
  <header class="gold-header" :style="GOLD_HEADER_STYLES">
    <div class="gold-header-center" :style="GOLD_HEADER_CENTER_STYLES">
      <img
          v-if="header.jackpot"
          :src="header.jackpot.href"
          alt="jackpot_balance"
      />
      <div class="gold-header-center-value" :style="GOLD_HEADER_CENTER_VALUE_STYLES">0.00</div>
    </div>
    <div v-if="header.rightSection" class="gold-header-right">
      <div class="gold-header-right-content">
        <gold-header-button
            :icon="header.rightSection.soundButton.href"
            :label="header.rightSection.soundButton.text"
            :big="header.rightSection.soundButton.big"
        ></gold-header-button>

        <gold-header-button
            :icon="header.rightSection.languageButton.href"
            :label="header.rightSection.languageButton.text"
            :big="header.rightSection.languageButton.big"
        ></gold-header-button>

        <gold-header-button
            :icon="header.rightSection.historyButton.href"
            :label="header.rightSection.historyButton.text"
            :big="header.rightSection.historyButton.big"
        ></gold-header-button>

        <gold-header-button
            :icon="header.rightSection.withdrawButton.href"
            :label="header.rightSection.withdrawButton.text"
            :big="header.rightSection.withdrawButton.big"
        ></gold-header-button>
      </div>
    </div>
  </header>
</template>

<script>
import GoldHeaderButton from "@/views/components/vlt-templates/Gold/vertical/common/header/GoldHeaderButton.vue";
import {onMounted, ref} from "@vue/composition-api";

export default {
  name: "GoldHeader",
  props: {
    header: {
      type: Object,
    },
  },

  setup() {

    const GOLD_HEADER_STYLES = ref({
      'padding': '10px 20px 10px 10px'
    })

    const GOLD_HEADER_CENTER_STYLES = ref({
      'text-align': 'center'
    })

    const GOLD_HEADER_CENTER_VALUE_STYLES = ref({
      'left': '50%',
      'transform': 'translate(-50%, -50%)'
    })

    return {
      GOLD_HEADER_STYLES,
      GOLD_HEADER_CENTER_STYLES,
      GOLD_HEADER_CENTER_VALUE_STYLES
    }

  },

  components: {
    GoldHeaderButton,
  },
};
</script>

<style scoped lang="scss">

.gold-header {
  display: grid;
  grid-template-columns: 1fr 0.35fr;
  gap: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  z-index: 1;

  &-center {
    display: flex;
    color: var(--primaryColor);
    font-weight: 700;
    font-size: 60px;
    position: relative;

    img {
      width: 100%;
    }

    &-value {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-right {
    display: flex;
    justify-content: center;

    &-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 30px;
    }
  }
}
</style>
