import axios from '@/libs/axios'

const URL = `${process.env.VUE_APP_URL}/vlt-templates-v2/gold`

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCoinsAnimations(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/coinsAnimation`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCoinsAnimation(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/coinsAnimation/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCoinsAnimation(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${URL}/coinsAnimation`, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateCoinsAnimation(ctx, {id, data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${URL}/coinsAnimation/${id}`, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeCoinsAnimation(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${URL}/coinsAnimation/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
