<template>
  <div class="gold-footer-item">
    <img :src="img" alt="gold-footer-item" />
    <span>{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: "GoldFooterGameItem",
  props: {
    img: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.gold-footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  color: var(--textColor);
  cursor: pointer;

  img {
    height: 7vh;
    width: auto;
  }
}
</style>
