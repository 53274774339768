<template>
  <header class="gold-header" :style="GOLD_HEADER_STYLES">
    <gold-header-left :leftSection="header.leftSection"></gold-header-left>
    <gold-header-center :jackpot="header.jackpot"></gold-header-center>
    <gold-header-right :rightSection="header.rightSection"></gold-header-right>
  </header>
</template>

<script>
import GoldHeaderLeft from "./GoldHeaderLeft.vue";
import GoldHeaderCenter from "./GoldHeaderCenter.vue";
import GoldHeaderRight from "./GoldHeaderRight.vue";
import {ref} from "@vue/composition-api";

export default {
  name: "GoldHeader",
  props: {
    header: {
      type: Object,
    },
  },
  components: {
    GoldHeaderLeft,
    GoldHeaderCenter,
    GoldHeaderRight,
  },

  setup() {
    const GOLD_HEADER_STYLES = ref({
      'padding': '10px'
    })

    return {
      GOLD_HEADER_STYLES
    }
  }
};
</script>

<style scoped lang="scss">
.gold-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
</style>
