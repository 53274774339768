<template>
  <div id="iconDropDownTE">
    <b-dropdown
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :text="selectedIcon ? 'Icono seleccionado' : 'Seleccionar icono'"
      block
    >
      <b-dropdown-item @click="selectIcon(null)" style="padding: 0 !important;">
        <span class="font-weight-bold" style="font-size: 13px">Ningún icono</span>
      </b-dropdown-item>

      <b-dropdown-divider></b-dropdown-divider>

      <div class="icon-grid" style="overflow-y: scroll; max-height: 400px">
        <section v-for="(icon, index) in icons" :key="index">
          <b-dropdown-item v-b-tooltip.hover :title="icon.text" @click="selectIcon(icon)" class="icon-item">
            <inline-svg :svg-data="icon.value" class="icon-preview"></inline-svg>
          </b-dropdown-item>
        </section>
      </div>
    </b-dropdown>

    <div v-if="selectedIcon" class="mt-1">
      <p style="font-size: 12px">Icono seleccionado:</p>
      <inline-svg
        :svg-data="selectedIcon.value"
        class="selected-icon"
      ></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from "@/views/components/whitelabel-templates/common/InlineSvg.vue";
import vSelect from "vue-select";

export default {
  name: "IconDropdown",
  components: { vSelect, InlineSvg },
  props: {
    icons: {
      type: Array,
      required: true,
    },
    selectedIconProp: {
      type: String,
    },
  },
  data() {
    return {
      selectedIcon: null,
    };
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon;
      this.$emit("icon-selected", icon);
    },
  },
  mounted() {
    if (this.selectedIconProp)
      this.selectedIcon = { value: this.selectedIconProp };
  },
};
</script>

<style lang="scss">
#iconDropDownTE .icon-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  &::before {
    content: "";
  }
}

.dark-layout #iconDropDownTE .dropdown-menu,
.dark-layout #iconDropDownTE .icon-drop-down,
.dark-layout #iconDropDownTE .icon-grid {
  background: #283046 !important;
  color: darkgray;
}

#iconDropDownTE .icon-item {
  display: grid;
  place-content: center;
}
#iconDropDownTE .icon-preview {
  width: 24px;
  height: 24px;
  display: inline-block;
}
#iconDropDownTE .selected-icon {
  width: 40px;
  height: 40px;
}
#iconDropDownTE .dropdown-item{
  border-radius: 5px;
}
</style>
