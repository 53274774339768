<template>
  <footer class="gold-footer" :style="GOLD_FOOTER_STYLES">
    <div class="gold-footer-content">
      <gold-footer-game-item
        v-if="footer.links.length > 0"
        v-for="(link, i) in footer.links"
        :key="i"
        :img="link.src"
        :name="link.title"
        :href="link.href"
      ></gold-footer-game-item>
    </div>
  </footer>
</template>

<script>
import GoldFooterGameItem from "@/views/components/vlt-templates/Gold/horizontal/common/footer/GoldFooterGameItem.vue";

export default {
  name: "GoldFooter",
  props: {
    footer: {
      type: Object,
    },
  },

  setup () {
    const GOLD_FOOTER_STYLES = {
      'background-image': 'var(--footer-bg-image)',
      'background-size': '78% 60%',
      'background-position': 'bottom',
      'left': '0',
      'background-repeat': 'no-repeat'
    }

    return {
      GOLD_FOOTER_STYLES
    }
  },

  components: {
    GoldFooterGameItem,
  },
};
</script>

<style scoped lang="scss">
.gold-footer {
  background-image: var(--footer-bg-image);
  background-position: bottom;
  background-size: 70% 55%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 1;
  box-sizing: inherit;

  &-content {
    display: flex;
    gap: 90px;
  }
}
</style>
