import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import lobbyStoreModule
    from "./store/lobbyStoreModule";
import {useRoute} from "@core/utils/utils";

export default function useLobbyList() {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const LOBBY_GOLD_STORE = "gold-lobby";

    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
        store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(LOBBY_GOLD_STORE))
        store.registerModule(LOBBY_GOLD_STORE, lobbyStoreModule);
    
    // Use toast
    const { t } = useI18nUtils();
    const toast = useToast()
    const refLobbyListTable = ref(null)
    const loading = ref(false);

    // Table Handlers
    const tableColumns = [
        { key: 'name', sortable: false, label: t('labels.title') },
        { key: 'actions', label: t('labels.actions') },
    ]
    const perPage = ref(10)
    const totalLobby = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    const recordDeleted = ref(false)
    const layout = ref('horizontal')

    const route = useRoute();

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const dataMeta = computed(() => {
        const localItemsCount = refLobbyListTable.value ? refLobbyListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLobby.value,
        }
    })
    const status = ref([
        {
            label:t('labels.all'),value:3
        },
        {
            label:t('labels.active'),value:1
        }
        ,{
            label:t('labels.disable'),value:0
        }
    ]);
    const statusSelected = ref({
        label:t('labels.all'),value:3
    });

    const refetchData = () => {
        refLobbyListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, recordDeleted,statusSelected], () => {
        refetchData()
    })

    const fetchLobby = (ctx, callback) => {
        console.log(editTemplate.value._id)
        loading.value = true
        const params = {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
            templateId: editTemplate.value._id,
            layout: layout.value,
        }
        if (statusSelected.value.value===1) params.status=true;
        if (statusSelected.value.value===0) params.status=false;

        store.dispatch(`${LOBBY_GOLD_STORE}/fetchLobbies`, params)
            .then(response => {
                const { lobbys, total } = response.data
                callback(lobbys)
                totalLobby.value = total
                loading.value = false
            })
            .catch(() => {
                loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching Lobby list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        fetchLobby,
        tableColumns,
        perPage,
        currentPage,
        totalLobby,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refLobbyListTable,
        refetchData,
        loading,
        recordDeleted,
        status,
        editTemplate,
        layout,
        route,
        statusSelected
    }
}
