<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  data() {
    return {
      providers: [
        "pragmaticplay",
        "pgsoft",
        "rubyplay",
        "redtiger",
        "3oaks",
        "belatra",
        "btg",
        "playson",
        "nolimit",
        "mr.slotty",
        "amusnet",
        "pascal",
      ],
      providersSmall: [
        "pragmaticplay",
        "belatra",
        "mr.slotty",
        "amusnet",
        "rubyplay",
      ]
    };
  },
  mounted() {
    new Swiper(this.$refs.appproviders, {
      init: true,
      slidesPerView: 5,
      grabCursor: true,

      on: {
        slideChange: swiper => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },

  setup() {
    const SWIPER_SLIDE_IMG = {
      'background': 'linear-gradient(135deg, #000 0%, #67625dc9 50%, #614225 51%, #1a1d23 100%) !important',
      'border-radius': '10px',
      'border': '2px solid #aa880b'
    }

    return {
      SWIPER_SLIDE_IMG
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="app-content" style="padding: 35px;">
      <div
          v-for="(item, index) in providers"
          :key="`app-item-${index}`"
          class="app-item">
        <img class="app-item-img" :src="`/img/providers/${item}.gif`" alt=""/>

        <img  class="app-item-provider" :src="`/img/providers/${item}-t.gif`" alt=""/>
      </div>
    </div>

    <div class="swiper app-providers" ref="appproviders">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             style="padding: 0 10px; text-align: center; margin: auto;"
             v-for="(item, index) in providersSmall"
             :key="`provider-${index}`">
          <img :style="SWIPER_SLIDE_IMG" :src="`/img/providers/${item}.png`" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app {

  &-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 19vh);
    column-gap: 45px;
    row-gap: 3vh;
    padding: 35px;
    height: 100%;
  }

  &-item {
    position: relative;
    height: 100%;

    &-img {
      width: 100%;
      height: 100%;
    }

    &-provider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }


  &-providers {
    height: 6vh;

    .swiper-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4vh;
      margin: auto;
      padding: 0 10px;
      width: 100%;

      img {
        background: linear-gradient(135deg, #000 0%, #67625dc9 50%, #614225 51%, #1a1d23 100%) !important;
        border-radius: 10px;
        border: 2px solid #aa880b;
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>