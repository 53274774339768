<template>
  <div :style="GOLD_COINS_STYLES" class="gold-coins"></div>
</template>

<script>
export default {
  name: "GoldCoinsAnimation",


  setup() {
    const GOLD_COINS_STYLES = {
      'background-image': 'var(--coins-animation-image)',
      'left': '0',
      'background-size': 'cover',
      'background-position': 'top'
    }
    return {
      GOLD_COINS_STYLES
    }
  }
};
</script>

<style scoped lang="scss">
.gold-coins {
  position: absolute;
  top: -120px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--coins-animation-image);
  background-size: cover;
  background-position: top;
  pointer-events: none;
}
</style>
