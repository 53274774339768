import { render, staticRenderFns } from "./GoldFooter.vue?vue&type=template&id=69323878&scoped=true"
import script from "./GoldFooter.vue?vue&type=script&lang=js"
export * from "./GoldFooter.vue?vue&type=script&lang=js"
import style0 from "./GoldFooter.vue?vue&type=style&index=0&id=69323878&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69323878",
  null
  
)

export default component.exports