import {ref, watch, computed, onUnmounted} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import {axiosErrorHandle} from "@core/utils/errorHandler";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import coinsAStoreModule from './store/coinsAStoreModule'
import {showErrorToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {useRoute} from "@core/utils/utils";


export default function useCoinsAnimationList() {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const GOLD_COINS_ANIMATION_STORE = "gold-coins-animation";

    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
        store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);


    if (!store.hasModule(GOLD_COINS_ANIMATION_STORE))
        store.registerModule(GOLD_COINS_ANIMATION_STORE, coinsAStoreModule);


    onUnmounted(() => {
        if (store.hasModule(GOLD_COINS_ANIMATION_STORE))
            store.unregisterModule(GOLD_COINS_ANIMATION_STORE);
    });

    const toast = useToast()
    const refCoinsAnimationListTable = ref(null)
    const loading = ref(false)
    const perPage = ref(10)
    const totalCoinsA = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    const recordDeleted = ref(false)
    const layout = ref('horizontal')

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const route = useRoute();

    const dataMeta = computed(() => {
        const localItemsCount = refCoinsAnimationListTable.value ? refCoinsAnimationListTable.value.localItems.length : 0

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCoinsA.value,
        }
    })

    // Table Handlers
    const tableColumns = [
        {key: 'name', sortable: true},
        {key: 'actions'},
    ]

    const refreshData = () => {
        refCoinsAnimationListTable.value.refresh()
    }


    const isSelected = (id) => {
        return editTemplate.value.templateData[layout.value]?.coinsAnimation._id === id
    }


    const findSelected = (id) => {
        return (
            refCoinsAnimationListTable.value?.localItems.find((item) => item._id === id) || null
        );
    };


    const selectCoinsAnimation = async (id) => {
        const selected = findSelected(id);

        if (!selected) return;

        const updatedTemplate = {
            ...editTemplate.value,
        };


        updatedTemplate.templateData[layout.value].coinsAnimation = selected

        store.commit('vlt-templates/SET_EDIT_TEMPLATE', updatedTemplate);


        try {
            await store.dispatch("vlt-templates/updateTemplate", {
                template: updatedTemplate,
                toast,
                path: `templateData.${layout.value}.coinsAnimation`
            });
        } catch (error) {
            showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
        }
    }

    watch([currentPage, perPage, searchQuery, recordDeleted], () => {
        refreshData()
    })

    const fetchCoinsAnimation = (ctx, callback) => {
        loading.value = true

        store.dispatch(`${GOLD_COINS_ANIMATION_STORE}/fetchCoinsAnimations`, {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            type: layout.value,
            sortDesc: isSortDirDesc.value,
            templateId: editTemplate.value._id,
            whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,

        })
            .then(response => {
                loading.value = false
                const {data, total} = response.data
                callback(data)
                totalCoinsA.value = total
            })
            .catch(async (error) => {
                    loading.value = false
                    showErrorToast(
                        toast,
                        "Error fetching Coins Animation list",
                        axiosErrorHandle(error)
                    );
                }
            )
    }


    return {
        tableColumns,
        perPage,
        currentPage,
        totalCoinsA,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCoinsAnimationListTable,
        refreshData,
        loading,
        recordDeleted,
        fetchCoinsAnimation,
        isSelected,
        route,
        layout,
        selectCoinsAnimation
    }
}
