import axios from '@axios'

const URL = `${process.env.VUE_APP_URL}/vlt-templates-v2/gold`


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchHeaders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/header`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeader(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/header/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addHeader(ctx, headerData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${URL}/header`, headerData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateHeader(ctx, {id, headerData}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${URL}/header/${id}`, headerData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeaderImage(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${URL}/files/${id}?path=headers`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeHeader(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${URL}/header/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
