import axios from "@/libs/axios";
const APP_URL = process.env.VUE_APP_URL;
import { showSuccessToast } from "@/views/components/whitelabel-templates/common/utils/showToast";

// Utility function to get a nested value of an object
function getNestedValue(obj, path) {
    return path.split(".").reduce((current, field) => {
        return current && current[field] !== undefined ? current[field] : undefined;
    }, obj);
}

// Utility function to set a value nested in an object
function setNestedValue(obj, path, value) {
    const fields = path.split(".");
    let current = obj;
    for (let i = 0; i < fields.length - 1; i++) {
        if (!current[fields[i]]) current[fields[i]] = {};
        current = current[fields[i]];
    }
    current[fields[fields.length - 1]] = value;
}

export default {
    // This module is namespaced, meaning its state is isolated from other modules
    namespaced: true,
    state: {
        // Current template being displayed
        currentTemplate: null,
        // Default templates from the server
        defaultsTemplates: [],
        // List of all templates
        templates: [],
        // Loading status for fetching templates
        loadingFetch: false,
        // Loading status for fetching a single template
        loadingFetchTemplate: true,
        // Loading status for fetching default templates
        loadingFetchDefaults: true,
        // Loading status for adding a new template
        loadingAdd: false,
        // Loading status for updating a template
        loadingUpdate: false,
        // Loading status for removing a template
        loadingRemove: false,
        // Template being edited
        editTemplate: null,
        // Recovery copy of the edited template
        editTemplateRecovery: null,
        // Flag to force a re-render of the iframe template
        reRenderTemplate: 0,
        settingTemplate: false,
        iframeChargerRef: null,
    },
    mutations: {
        SET_IFRAME_CHARGER_REF(state, iframeRef) {
            state.iframeChargerRef = iframeRef;
        },
        // Set the template being edited
        SET_EDIT_TEMPLATE(state, template) {
            console.log("trying to update the template");
            state.editTemplate = template;
        },
        // Set the recovery copy of the edited template
        SET_EDIT_TEMPLATE_RECOVERY(state, template) {
            state.editTemplateRecovery = template;
        },
        SET_EDIT_UPDATED_TEMPLATE(state, {path, template}){
            console.log("We update the template in the api, now we are going to update the object with the response from the api")
            const backupValue = getNestedValue(template, path);
            console.log("backupValue", backupValue);
            if (backupValue !== undefined) {
                setNestedValue(state.editTemplate, path, backupValue);
            } else {
                console.warn(
                    `No se encontró el valor en el objeto de respaldo para el path: ${path}`
                );
            }
        },
        // Reset the edited template to the recovery copy
        RESET_EDIT_TEMPLATE(state, path) {
            console.log("trying to reset the template");
            console.log("recoveryTemplate", state.editTemplateRecovery)
            console.log("path", path);
            const backupValue = getNestedValue(JSON.parse(JSON.stringify(state.editTemplateRecovery)), path);
            console.log("backupValue", backupValue);
            if (backupValue !== undefined) {
                setNestedValue(state.editTemplate, path, backupValue);
            } else {
                console.warn(
                    `No se encontró el valor en el objeto de respaldo para el path: ${path}`
                );
            }
        },
        // Increment the re-render flag to force a re-render
        // RE_RENDER_TEMPLATE(state) {
        //     console.log("OBSOLETE -> RE_RENDER_TEMPLATE");
        //     // state.reRenderTemplate = state.reRenderTemplate + 1;
        // },
        RE_RENDER_TEMPLATE(state) {
            state.reRenderTemplate = state.reRenderTemplate + 1;
        },
        // Set the loading status for fetching templates
        SET_LOADING_FETCH(state, loadingStatus) {
            state.loadingFetch = loadingStatus;
        },
        // Set the loading status for fetching a single template
        SET_LOADING_FETCH_TEMPLATE(state, loadingStatus) {
            state.loadingFetchTemplate = loadingStatus;
        },
        // Set the loading status for fetching default templates
        SET_LOADING_FETCH_DEFAULTS(state, loadingStatus) {
            state.loadingFetchDefaults = loadingStatus;
        },
        // Set the loading status for adding a new template
        SET_LOADING_ADD(state, loadingStatus) {
            state.loadingAdd = loadingStatus;
        },
        // Set the loading status for updating a template
        SET_LOADING_UPDATE(state, loadingStatus) {
            state.loadingUpdate = loadingStatus;
        },
        // Set the loading status for removing a template
        SET_LOADING_REMOVE(state, loadingStatus) {
            state.loadingRemove = loadingStatus;
        },
        // Set the list of templates
        SET_TEMPLATES(state, templates) {
            state.templates = templates;
        },
        // Set the current template being displayed
        SET_CURRENT_TEMPLATE(state, template) {
            state.currentTemplate = template;
        },
        SET_SETTING_TEMPLATE(state, loadingStatus) {
            state.settingTemplate = loadingStatus;
        },
    },
    actions: {
        // Fetch default templates from the server
        fetchDefaultTemplates({ commit }, queryParams) {
            commit("SET_LOADING_FETCH_DEFAULTS", true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/vlt-templates-v2/default-templates`, {
                        params: queryParams,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit("SET_LOADING_FETCH_DEFAULTS", false);
                    });
            });
        },
        // Fetch templates from the server
        fetchTemplates({ commit }, queryParams) {
            commit("SET_LOADING_FETCH", true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/vlt-templates-v2/templates`, { params: queryParams })
                    .then((response) => {
                        commit("SET_TEMPLATES", response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit("SET_LOADING_FETCH", false);
                    });
            });
        },
        // Add a new template to the server
        addTemplate({ commit }, templateData) {
            commit("SET_LOADING_ADD", true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/vlt-templates-v2/default-templates`, templateData)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
                    .finally(() => {
                        commit("SET_LOADING_ADD", false);
                    });
            });
        },
        // Fetch a single template from the server
        fetchTemplate({ commit }, { id, params }) {
            commit("SET_LOADING_FETCH_TEMPLATE", true);

            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/vlt-templates-v2/whitelabel-templates/${id}`, { params })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit("SET_LOADING_FETCH_TEMPLATE", false);
                    });
            });
        },
        // Update a template on the server
        updateTemplate({ commit }, { template, toast, path }) {
            commit("SET_LOADING_UPDATE", true);

            showSuccessToast(
                toast,
                "Template",
                "Please wait while we update the template"
            );

            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/vlt-templates-v2/template/${template._id}`, {
                        templateId: template._id,
                        template: template.templateData,
                    })
                    .then((response) => {
                        showSuccessToast(
                            toast,
                            "Template",
                            "Template updated successfully"
                        );

                        commit(
                            "SET_EDIT_TEMPLATE_RECOVERY",
                            JSON.parse(JSON.stringify(response.data))
                        );

                        //update the template with the api data
                        commit(
                            "SET_EDIT_UPDATED_TEMPLATE",
                            {path, template: response.data}
                        )

                        resolve(response);
                    })
                    .catch((error) => reject(error))
                    .finally(() => {
                        commit("SET_LOADING_UPDATE", false);
                    });
            });
        },
        // Remove a template from the server
        removeTemplate({ commit }, queryParams) {
            commit("SET_LOADING_REMOVE", true);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/vlt-templates-v2/template`, { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
                    .finally(() => {
                        commit("SET_LOADING_REMOVE", false);
                    });
            });
        },
        setTemplate({ commit }, params) {
            commit("SET_SETTING_TEMPLATE", true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/vlt-templates-v2/whitelabel-templates/default`, params)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
                    .finally(() => {
                        commit("SET_SETTING_TEMPLATE", false);
                    });
            });
        },
    },
};
