<template>
  <div class="gold-header-right-item">
    <button style="text-align: center" :class="['gold-header-right-btn', { big: big }]">
      <img :src="icon" :alt="label"/>
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "GoldHeaderButton",
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.gold-header-right {
  &-item {
    display: flex;
    justify-content: center;
  }

  &-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    color: var(--textColor);
    text-align: center;

    img {
      width: 50px;
      height: 50px;
    }

    &.big {
      img {
        height: 5vh !important;
        width: auto !important;
        max-width: auto !important;
      }
    }
  }
}
</style>
