<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton, BFormSelect,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import {computed, onMounted, ref} from "@vue/composition-api";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {useRoute} from "@core/utils/utils";
import {UseIndex} from "@/views/components/vlt-templates/common/composables/useIndex";
import coinsAStoreModule from "./store/coinsAStoreModule";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {
  COINS_ANIMATION
} from "@/views/components/vlt-templates/Gold/config/PageEdit/CoinsAnimationManagement/models/coinsAnimation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import VideoFieldForm from "@/views/components/VideoFieldForm.vue";

const APP_URL = process.env.VUE_APP_URL;

export default {
  name: 'index',

  props: {
    options: {
      type: Object,
      default: () => ({
        action: 'edit',
        itemsSelected: null,
        title: '',
        type: 'coinsAnimation',
        visible: false
      })
    }
  },

  components: {
    VideoFieldForm,
    ImageFieldForm,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  setup(props, {emit}) {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const C_ANIMATION_GOLD_STORE = "gold-coins-animation-store";
    const coinsAnimation = ref(null)
    const route = useRoute();
    const rBuilder = RequestBuilder();

    if (!store.hasModule(MODULE_VLT_STORE_NAME))
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    if (!store.hasModule(C_ANIMATION_GOLD_STORE))
      store.registerModule(C_ANIMATION_GOLD_STORE, coinsAStoreModule);

    const {
      resetForm,
      toast,
      loading,
      getValidationState,
      editTemplate,
      refFormObserver
    } = UseIndex();

    onMounted(() => {
      initCoinsAnimation();
      const {itemsSelected} = props.options;
      if (itemsSelected) coinsAnimation.value = itemsSelected;
      updatePreview();
    });

    const isEditMode = computed(() => props.options.action === "edit");

    const getLayout = computed(() => route.query["layout"])

    const onSubmit = () => {
      if (isEditMode.value) {
        updateCoinsAnimation();
      } else {
        createCoinsAnimation();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("vlt-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: `templateData.${getLayout.value}.coinsAnimation`
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createCoinsAnimation = async () => {
      loading.value = true;
      coinsAnimation.value.whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      coinsAnimation.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${C_ANIMATION_GOLD_STORE}/addCoinsAnimation`,
            rBuilder.clean(coinsAnimation.value)
        );

        showSuccessToast(toast, "Coins Animation", i18n.t("Coins Animation Created"));

        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("Error creating or updating coins animation"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateCoinsAnimation = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(`${C_ANIMATION_GOLD_STORE}/updateCoinsAnimation`, {
          id: coinsAnimation.value._id,
          data: rBuilder.clean(coinsAnimation.value)
        });
        showSuccessToast(toast, "Coins Animation", i18n.t("Coin animation updated"));

        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("Error creating or updating coins animation"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const initCoinsAnimation = () => {
      coinsAnimation.value = {...COINS_ANIMATION[getLayout.value]}
    };

    const resetData = () => {
      store.commit(`${MODULE_VLT_STORE_NAME}/RESET_EDIT_TEMPLATE`, `templateData.${getLayout.value}.coinsAnimation`);
      store.commit(`${MODULE_VLT_STORE_NAME}/RE_RENDER_TEMPLATE`);
      emit("reset");
    };

    const updatePreview = (value) => {
      const updateTemplate = {...editTemplate.value}
      updateTemplate.templateData[getLayout.value].coinsAnimation = value || coinsAnimation.value
      store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updateTemplate);
    };


    const errorInLoadFile = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedFile = (data, path) => {
      if (data?.files.length) {
        const file = data.files[data.files.length - 1];
        if (path) {
          coinsAnimation.value[path] = file
        }
        updatePreview();
      }
    };

    const onClearImage = (path) => {
      coinsAnimation.value[path] = ''
    }

    const isVertical = () => {
      return getLayout.value === 'vertical';
    }

    const onFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.type === 'video/webm') {
        coinsAnimation.value.href_video = URL.createObjectURL(selectedFile);
      } else {
        alert('Please select a valid .webm video file.');
      }
    }

    return {
      coinsAnimation,
      isEditMode,
      updateTemplate,
      editTemplate,
      getValidationState,
      resetForm,
      loading,
      updatePreview,
      onFileChange,
      getLayout,
      onUploadedFile,
      onSubmit,
      resetData,
      errorInLoadFile,
      onClearImage,
      isVertical,
      refFormObserver
    }
  },
}
</script>

<template>
  <b-tabs v-if="coinsAnimation" content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }" @reset.prevent="resetForm">
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tab title="Content" active>
          <!-- Banner name -->
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
            <b-form-group
                label="Name"
                label-for="name">
              <template #label>{{ $t('labels.name') }}</template>

              <b-form-input
                  id="name"
                  v-model="coinsAnimation.name"
                  trim/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>


          <div class="mt-1" v-if="isVertical()">
            <validation-provider
                #default="validationContext"
                name="w-image"
                class="w-100">

              <b-form-group label="Color" label-for="w-image">
                <template #label>{{ $t("Animation") }}</template>
                <b-row>
                  <b-col>
                    <!-- Styled -->
                    <video-field-form
                        class="w-100"
                        accept=".webm"
                        text="Animation"
                        :value="coinsAnimation.href_video"
                        @uploaded="onUploadedFile($event, 'href_video')"
                        @error="errorInLoadFile()"
                        path="vlt/coinsAnimation">
                    </video-field-form>
                  </b-col>
                </b-row>

                <div class="row" v-if="coinsAnimation.href_video.length > 0">
                  <div class="col">
                    <div class="mt-2">
                      <video style="max-height: 250px;"
                             class="rounded w-100 "
                             autoplay
                             muted
                             loop
                             :src="coinsAnimation.href_video">
                      </video>
                    </div>

                    <b-button class="close-btn" @click="onClearImage('href_video')"
                              variant="outline-primary"
                              size="sm">X
                    </b-button>
                  </div>
                </div>


                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </div>

          <div class="mt-1" v-if="!isVertical()">
            <validation-provider
                #default="validationContext"
                name="w-image"
                class="w-100">

              <b-form-group label="Color" label-for="w-image">
                <template #label>{{ $t("Image") }}</template>
                <image-field-form
                    :single-button="true"
                    class="w-100"
                    text="Image"
                    :value="coinsAnimation.href"
                    path="vlt/coinsAnimation"
                    @uploadedImage="onUploadedFile($event, 'href')"
                    @error="errorInLoadFile()"
                    preview="true">
                </image-field-form>

                <div class="row" v-if="coinsAnimation.href.length > 0">
                  <div class="col">
                    <img
                        style="max-width: 100%"
                        :src="coinsAnimation.href"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />

                    <b-button class="close-btn" @click="onClearImage('href')"
                              variant="outline-primary"
                              size="sm">X
                    </b-button>
                  </div>
                </div>


                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </div>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

#add-new-banner-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.close-btn {
  right: 15px;
  top: 5px;
  margin-top: 5px;
  position: absolute;
}
</style>
