<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('buttons.search') + '...'"
              />
              <div>
                <b-button variant="primary" @click="prepareUpdateOrCreate(null, 'create')">
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">
        <b-table
            ref="refCoinsAnimationListTable"
            class="position-relative"
            :items="fetchCoinsAnimation"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="this.$t('messages.nomatching')"
            :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.name }}</small>
            </b-media>
          </template>

          <!-- Column: Description -->
          <template #cell(description)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.description }}</small>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      @click="selectCoinsAnimation(data.item._id)"
                      v-b-tooltip.hover.top="$t('labels.select')" size="sm">
              <feather-icon :icon="isSelected(data.item._id) ? 'CheckCircleIcon' : 'CircleIcon'"/>

            </b-button>

            <b-button v-if="isSelected(data.item._id)" variant="primary" class="btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.top="$t('tooltips.edit')" size="sm"
                      @click="prepareUpdateOrCreate(data.item, 'edit')"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      v-if="!isSelected(data.item._id)"
                      v-b-tooltip.hover.top="$t('tooltips.delete')" @click="removeCoinsAnimation(data.item._id)"
                      size="sm">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalCoinsA"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {ref, onBeforeMount} from "@vue/composition-api";
import ImageTableField from "@/views/components/ImageTableField.vue";
import useCoinsAnimationList
  from "@/views/components/vlt-templates/Gold/config/PageEdit/CoinsAnimationManagement/useCoinsAnimationList";
import {
  COINS_ANIMATION
} from "@/views/components/vlt-templates/Gold/config/PageEdit/CoinsAnimationManagement/models/coinsAnimation";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  components: {
    ImageTableField,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  computed: {
    tableColumns() {
      return [
        {key: "name", sortable: true, label: this.$t("labels.name")},
        {key: "actions", label: this.$t("labels.actions")},
      ]
    }
  },

  watch: {
    $route(to) {
      this.layout = to.query.layout
      this.refreshData()
    }
  },

  setup(props, {emit}) {
    const isAddNewCoinsAnimation = ref(false);

    const prepareUpdateOrCreate = (item = null, action = 'edit') => {
      emit('updateOrCreate', {item, action})
    }

    onBeforeMount(() => {
      layout.value = route.query.layout
    })

    const {
      fetchCoinsAnimation,
      perPage,
      currentPage,
      totalCoinsA,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCoinsAnimationListTable,
      refreshData,
      recordDeleted,
      selectCoinsAnimation,
      loading,
      isSelected,
      layout,
      route,
      resolveCoinsAStatusVariant,
    } = useCoinsAnimationList();

    return {
      // Sidebar
      isAddNewCoinsAnimation,
      fetchCoinsAnimation,
      perPage,
      currentPage,
      totalCoinsA,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCoinsAnimationListTable,
      refreshData,
      recordDeleted,
      selectCoinsAnimation,
      isSelected,
      loading,
      prepareUpdateOrCreate,
      resolveCoinsAStatusVariant,
    };
  },
  methods: {
    removeCoinsAnimation(Id) {
      store.dispatch(`${COINS_ANIMATION}/removeCoinsAnimation`, {id: Id}).then(() => {
        this.recordDeleted = !this.recordDeleted;
        showSuccessToast(this.$toast, "Coins Animation", "Removed successfully");
        this.refreshData
      });
    }, catch (error) {
      showErrorToast(this.$toast, "Error", axiosErrorHandle(error));
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
</style>
