<script>
/* eslint-disable */
import GoldCoinsAnimation from "./common/GoldCoinsAnimation.vue";
import GoldHeader from "./common/header/GoldHeader.vue";
import GoldFooter from "./common/footer/GoldFooter.vue";
import {createCssVar} from "@core/utils/createCssVar";
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import store from "@/store";
import goldStoreModule from "@/views/components/vlt-templates/Gold/store/goldStoreModule";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import GoldHomeVerticalPage from "@/views/components/vlt-templates/Gold/vertical/views/GoldHomeVerticalPage.vue";

const MODULE_GOLD_STORE_NAME = "gold-store";
const MODULE_APP_STORE_NAME = "app-vlt-templates";

export default {
  name: "GoldVerticalTemplate",
  components: {
    GoldHomeVerticalPage,
    GoldCoinsAnimation,
    GoldHeader,
    GoldFooter,
  },

  setup() {
    // Register modules
    if (!store.hasModule(MODULE_GOLD_STORE_NAME))
      store.registerModule(MODULE_GOLD_STORE_NAME, goldStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, vltTemplatesModule);

    const el = ref(null);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const mainColors = computed(
        () => editTemplate.value.templateData.vertical.main.colors
    );

    const footerConfig = computed(() => editTemplate.value.templateData.vertical.footer);

    const headerConfig = computed(() => editTemplate.value.templateData.vertical.header);

    const coinsAnimationConfig = computed(() => editTemplate.value.templateData.vertical?.coinsAnimation)

    const bodyConfig = computed(() => editTemplate.value.templateData.vertical.body);

    const preview = computed(() => store.state["gold-store"].preview);

    const setCssVar = () => {

      if (!el.value) return;
      createCssVar(el.value, {
        "--primaryColor": mainColors.value.primaryColor,
        "--textColor": mainColors.value.textColor,
        "--bodyColor": mainColors.value.bodyColor,
        "--backgroundColor": mainColors.value.backgroundColor,

        "--gradient_1_c": footerConfig.value?.balance.gradient_1_c || '',
        "--gradient_2_c": footerConfig.value?.balance.gradient_2_c || '',
        "--gradient_3_c": footerConfig.value?.balance.gradient_3_c || '',
        "--gradient_4_c": footerConfig.value?.balance.gradient_4_c || '',
        "--border_color": footerConfig.value?.balance.border_color || '',
        "--color": footerConfig.value?.balance.color || '',

        "--footer-bg-image": `url(${footerConfig.value.href})` || 'none',
        "--body-bg-image": `url(${bodyConfig.value.href})` || 'none'

      });

    };

    watch([mainColors, footerConfig], setCssVar, {
      deep: true,
      immediate: true,
    });

    const GOLD_LAYOUT_STYLES = ref({
      'background-image': 'var(--body-bg-image)',
      'background-size': 'cover',
    })


    onMounted(() => {
      setCssVar()
    });

    return {
      el,
      editTemplate,
      setCssVar,
      preview,
      mainColors,
      coinsAnimationConfig,
      footerConfig,
      headerConfig,
      GOLD_LAYOUT_STYLES
    }
  },
};
</script>

<template>
  <div ref="el" class="gold-layout" :style="GOLD_LAYOUT_STYLES">
    <gold-coins-animation v-if="coinsAnimationConfig" :animationConfig="coinsAnimationConfig"></gold-coins-animation>
    <gold-header v-if="headerConfig" :header="headerConfig"></gold-header>
    <gold-home-vertical-page></gold-home-vertical-page>
    <gold-footer v-if="footerConfig" :footer="footerConfig"></gold-footer>
  </div>
</template>

<style lang="scss" scoped>

.gold-layout {
  min-height: 100dvh;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
</style>
