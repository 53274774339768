<script>
import GoldCoinsAnimation from "@/views/components/vlt-templates/Gold/horizontal/common/GoldCoinsAnimation.vue";
import GoldHeader from "@/views/components/vlt-templates/Gold/horizontal/common/header/GoldHeader.vue";
import GoldFooter from "@/views/components/vlt-templates/Gold/horizontal/common/footer/GoldFooter.vue";
import {createCssVar} from "@core/utils/createCssVar";
import store from "@/store";
import goldStoreModule from "@/views/components/vlt-templates/Gold/store/goldStoreModule";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import GoldHomeHorizontalPage from "@/views/components/vlt-templates/Gold/horizontal/views/GoldHomeHorizontalPage.vue";

const MODULE_GOLD_STORE_NAME = "gold-store";
const MODULE_APP_STORE_NAME = "app-vlt-templates";

export default {
  components: {GoldHomeHorizontalPage, GoldFooter, GoldHeader, GoldCoinsAnimation},

  setup() {
    if (!store.hasModule(MODULE_GOLD_STORE_NAME))
      store.registerModule(MODULE_GOLD_STORE_NAME, goldStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, vltTemplatesModule);

    const el = ref(null);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const mainColors = computed(
        () => editTemplate.value.templateData.horizontal.main.colors
    );

    const bodyConfig = computed(() => editTemplate.value.templateData.horizontal.body);

    const footerConfig = computed(() => editTemplate.value.templateData.horizontal.footer);

    const headerConfig = computed(() => editTemplate.value.templateData.horizontal.header);

    const coinsAnimationConfig = computed(() => editTemplate.value.templateData.horizontal?.coinsAnimation)

    const preview = computed(() => store.state["gold-store"].preview);

    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--primaryColor": mainColors.value.primaryColor,
        "--textColor": mainColors.value.textColor,
        "--coins-animation-image": `url(${coinsAnimationConfig.value.href})` || 'none',
        "--footer-bg-image": `url(${footerConfig.value.href})` || 'none',
        "--body-bg-image": `url(${bodyConfig.value.href})` || 'none'
      });
    }

    const GOLD_LAYOUT_STYLES = ref({
      'background-image': 'var(--body-bg-image)',
      'background-size': 'cover',
      'padding': '10px'
    })

    watch(mainColors, setCssVar, {
      deep: true,
      immediate: true,
    });

    onMounted(() => {
      setCssVar()
    });

    return {
      el,
      editTemplate,
      setCssVar,
      preview,
      mainColors,
      footerConfig,
      headerConfig,
      coinsAnimationConfig,
      GOLD_LAYOUT_STYLES,
    }

  },

};
</script>

<template>
  <div ref="el" class="gold-layout" :style="GOLD_LAYOUT_STYLES">
    <gold-coins-animation v-if="coinsAnimationConfig" :animationConfig="coinsAnimationConfig"/>
    <gold-header v-if="headerConfig" :header="headerConfig"/>
    <gold-home-horizontal-page/>
    <gold-footer v-if="footerConfig" :footer="footerConfig"/>
  </div>
</template>

<style scoped lang="scss">
.gold-layout {
  min-height: 100dvh;
  background-image: var(--body-bg-image) !important;
  background-size: cover;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
</style>
