import store from "@/store";
import {computed, onUnmounted, ref, watch} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import {
    showErrorToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import footerStoreModule
    from "@/views/components/vlt-templates/Gold/config/PageEdit/FooterManagement/store/footerStoreModule";
import {useRoute} from "@core/utils/utils";

export default function useFootersList() {
    const MODULE_VLT_STORE_NAME = "vlt-templates";
    const FOOTER_GOLD_STORE_MODULE_NAME = "gold-footer";

    // Register module
    if (!store.hasModule(MODULE_VLT_STORE_NAME))
        store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);


    if (!store.hasModule(FOOTER_GOLD_STORE_MODULE_NAME))
        store.registerModule(FOOTER_GOLD_STORE_MODULE_NAME, footerStoreModule);

    onUnmounted(() => {
        if (store.hasModule(FOOTER_GOLD_STORE_MODULE_NAME))
            store.unregisterModule(FOOTER_GOLD_STORE_MODULE_NAME);
    });

    const toast = useToast();
    const refFooterListTable = ref(null);
    const loading = ref(false);
    const perPage = ref(10);
    const totalFooters = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);
    const recordDeleted = ref(false);
    const layout = ref('horizontal')

    const route = useRoute();

    const editTemplate = computed(
        () => store.state[MODULE_VLT_STORE_NAME].editTemplate
    );

    const dataMeta = computed(() => {
        const localItemsCount = refFooterListTable.value?.localItems.length || 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalFooters.value,
        };
    });


    const refetchData = () => {
        refFooterListTable.value?.refresh();
    };

    const isSelected = (id) => editTemplate.value.templateData[layout.value]?.footer._id === id;

    const findSelected = (id) => {
        return (
            refFooterListTable.value?.localItems.find((item) => item._id === id) ||
            null
        );
    };

    const selectFooter = async (id) => {
        const selected = findSelected(id);

        if (!selected) return;

        const updatedTemplate = {
            ...editTemplate.value,
        };

        updatedTemplate.templateData[layout.value].footer = selected

        store.commit(`${MODULE_VLT_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);

        try {
            await store.dispatch("vlt-templates/updateTemplate", {
                template: updatedTemplate,
                toast,
                path: `templateData.footer.${layout.value}`
            });
        } catch (error) {
            showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
        }
    };

    const fetchFooters = (ctx, callback) => {
        loading.value = true;

        store
            .dispatch("gold-footer/fetchFooters", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
                templateId: editTemplate.value._id,
                type: layout.value
            })
            .then((response) => {
                loading.value = false;
                const {data, total} = response.data;
                callback(data);
                totalFooters.value = total;
            })
            .catch((error) => {
                loading.value = false;
                showErrorToast(
                    toast,
                    "Error fetching footers list",
                    axiosErrorHandle(error)
                );
            });
    };

    watch([currentPage, perPage, searchQuery, recordDeleted], () => {
        refetchData();
    });

    return {
        fetchFooters,
        perPage,
        currentPage,
        totalFooters,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refFooterListTable,
        refetchData,
        recordDeleted,
        selectFooter,
        isSelected,
        loading,
        layout,
        route,
        toast
    };
}
