<script>

import Vue from "vue";
import {BCard, BFormFile, BModal} from "bootstrap-vue";
import {isArray} from "vue-context/src/js/utils";
import axios from "@axios";

export default {
  name: 'VideoFieldForm',
  components: {
    BModal, BCard,
    BFormFile
  },
  props: {
    value: {type: String},
    text: {type: String},
    path: {type: String},
  },
  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      modal: {
        show: false,
        subtitle: "",
        percent: 0
      },
      videoFile: null
    };
  },

  model: {
    prop: "value",
    event: "value-changed",
  },

  computed: {
    valueLocal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("value-changed", val);
      },
    },
  },

  methods: {
    async saveVideo(event) {
      if (!event.target.files[0].type.startsWith("video/")) {
        const error = this.$t("invalid_file_type");
        console.log(error);
        this.$emit("error", error);
        return;
      }

      setTimeout(() => {
        this.$emit('preview')
      }, 1000)


      let size = parseFloat(event.target.files[0].size / 1024).toFixed(2);
      let maxSize = 2048; // 2097152 byte
      if (size > maxSize) {
        const error = this.$t("image_size_limit", {size})
        console.log(error)
        this.$emit("error", error);
        return;
      }

      const formData = new FormData();
      for (const e of event.target.files) {
        formData.append("images", e, e.name);
      }
      formData.append("path", this.path);

      this.setModal({percent: 0, show: true})

      axios
          .post(
              `${this.APP_URL}/setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
              formData, {
                onUploadProgress: (progressEvent) => {
                  const loadPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                  );
                  this.setModal({percent: loadPercentage, show: true})
                },
              }
          )
          .then((res) => {
            this.$emit("change", "reload");
            this.$emit("uploaded", res.data);
          })
          .catch((err) => {
            console.log(err);
            this.setModal({percent: 0, show: false})
            this.$emit("error");
          });
    },

    setModal({percent, show}) {
      this.modal.show = show
      this.modal.percent = percent
      this.modal.subtitle = percent === 100 ? this.$t("successfully_uploaded") : this.$t("uploading")
      if (this.modal.show === true && this.modal.percent >= 100) {
        console.log(this.modal.percent)
        setTimeout(() => {
          this.modal.show = false
          this.modal.percent = 0
        }, 500)
      }
    },
  }
}
</script>

<template>
  <div>
    <b-row>
      <b-col>
        <!-- Styled -->
        <b-form-file
            accept=".webm"
            v-model="videoFile"
            :placeholder="$t('upload file here...')"
            drop-placeholder="Drop file here..."
            @change="saveVideo">
        </b-form-file>
        <!--<div class="mt-1 font-small-3">Selected file: {{ imgFile ? imgFile.name : '' }}</div>-->
      </b-col>
    </b-row>


    <b-modal
        v-model="modal.show"
        no-close-on-esc
        no-close-on-backdrop
        size="md"
        cancel-variant="outline-secondary"
        centered
        title="Uploading file"
        hide-footer
        hide-header-close
    >
      <b-card>
        <div class="d-flex flex-column flex-wrap align-items-center justify-content-center pa-3">
          <div class="mb-1 font-weight-bold font-medium-2">{{ modal.subtitle }}</div>
          <b-progress class="w-100" :value="modal.percent" :max="100" show-progress animated></b-progress>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">

</style>