<template>
  <div class="preview" ref="preview">
    <b-spinner v-if="loading" class="spinner"></b-spinner>
    <Iframe
      :key="reRender"
      :show-scroll="showScroll"
      @iframe-loaded="handleIframeLoaded"
      ref="defaultTemplateIframe"
      :hover-edit-component="hoverEditComponent"
      :style="iframeStyle">
      <v-gold-template v-if="isVertical" />
      <h-gold-template v-else/>
    </Iframe>
  </div>
</template>

<script>
import Iframe from "@/views/components/vlt-templates/Gold/Iframe.js";
import VGoldTemplate from "@/views/components/vlt-templates/Gold/vertical/GoldVerticalTemplate.vue"
import HGoldTemplate from "@/views/components/vlt-templates/Gold/horizontal/GoldHorizontalTemplate.vue";
import {ref, computed, onBeforeMount, onMounted} from "@vue/composition-api";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
const MODULE_VLT_STORE_NAME = "vlt-templates";

export default {
  name: "GoldCharger",
  components: {
    VGoldTemplate,
    HGoldTemplate,
    Iframe,
  },
  props: {
    size: String,
    hoverEditComponent: Boolean,
    customWidth: String,
    customHeight: String,
    layout: String,
    reRender: {
      type: Number,
      default: 0,
    },
    showScroll: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    if (!store.hasModule(MODULE_VLT_STORE_NAME)) {
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    }

    const loading = ref(true);
    const head = ref("");
    const defaultTemplateIframe = ref(null);
    const preview = ref(null);

    const scale = computed(
      () =>
        ({
          smartphone: "scale(.8)",
          tablet: "scale(.9)",
          square: "scale(.95)",
          monitor: "scale(1)",
        }[props.size])
    );

    const isVertical = computed(() => {
      return props.layout === 'vertical'
    })

    const previewSize = computed(
      () =>
        ({
          smartphone: { width: "350px", height: "500px" },
          tablet: { width: "750px", height: "800px" },
          square: { width: "1024px", height: "800px" },
          monitor: { width: "100%", height: "100%" },
        }[props.size])
    );

    const iframeStyle = computed(() => ({
      ...previewSize.value,
      width: props.customWidth || previewSize.value.width,
      height: props.customHeight || previewSize.value.height,
      border: "none",
      transform: `${scale.value} translate(0,0)`,
    }));

    const handleIframeLoaded = () => {
      loading.value = false;
    };

    onBeforeMount(() => {
      head.value = `<head>${document.head.innerHTML}</head>`;
    });

    onMounted(() => {
      store.commit(
        `${MODULE_VLT_STORE_NAME}/SET_IFRAME_CHARGER_REF`,
        defaultTemplateIframe.value
      );
    });

    return {
      loading,
      head,
      defaultTemplateIframe,
      preview,
      iframeStyle,
      isVertical,
      handleIframeLoaded,
    };
  },
};
</script>

<style scoped>
.preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.spinner {
  align-self: center;
  position: absolute;
  z-index: 1000;
}

.preview::-webkit-scrollbar {
  -webkit-appearance: none;
}

.preview::-webkit-scrollbar:vertical {
  width: 10px;
}

.preview::-webkit-scrollbar-button:increment,
.preview::-webkit-scrollbar-button {
  display: none;
}

.preview::-webkit-scrollbar:horizontal {
  height: 10px;
}

.preview::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.preview::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
