<template>
  <video autoplay="autoplay" muted="muted" loop="loop" style="left: 0;" class="gold-coins">
    <source
      id="webm"
      :src="animationConfig.href_video"
      type="video/webm"
    />
  </video>
</template>

<script>

export default {
  name: "GoldCoinsVideo",
  props: {
    animationConfig: {
      type: Object,
    },
  }
};
</script>

<style scoped lang="scss">
.gold-coins {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
</style>
