<template>
  <transition name="slide-x">
    <div class="app-g-edit-card">
      <section
          v-for="(item, index) in menu"
          :key="index"
          class="menu-item"
          @click="expandMenu(index)"
      >
        <div class="menu-header">
          <h6 class="title">{{ item.name }}</h6>
          <feather-icon
              :icon="item.collapsed ? 'ChevronUpIcon' : 'ChevronRightIcon'"
              size="16"
          />
        </div>
      </section>

      <general-edit-color v-if="menu[0].collapsed"/>
      <general-edit-background v-if="menu[1].collapsed"/>
    </div>
  </transition>
</template>

<script>
import GeneralEditColor
  from "@/views/components/vlt-templates/Gold/config/GeneralEdit/Colors/GeneralEditColor.vue";
import GeneralEditBackground
  from "@/views/components/vlt-templates/Gold/config/GeneralEdit/BackgroundImg/GeneralEditBackground.vue";

export default {
  name: "AppGEdit",
  components: {GeneralEditColor, GeneralEditBackground},
  data() {
    return {
      menu: [
        {name: "Color", window: "g-edit-color", collapsed: false},
        {name: "Background", window: "g-edit-background", collapsed: false},
      ],
      selectedMenuWindow: "",
    };
  },
  methods: {
    expandMenu(index) {
      this.menu = this.menu.map((item, i) => ({
        ...item,
        collapsed: i === index ? !item.collapsed : false,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.app-g-edit-card {
  width: fit-content;
  min-height: 200px;
  position: absolute;
  background: white;
  top: 15px;
  left: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;

  .menu-item {
    margin-inline: 10px;
  }

  .menu-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      margin-right: 1rem;
    }
  }
}

.dark-layout .app-g-edit-card {
  background: #283046;
}

.slide-x-enter-active,
.slide-x-leave-active {
  transition: all 0.25s ease;
}

.slide-x-enter {
  transform: translateX(-15%);
}

.slide-x-leave-to {
  opacity: 0;
}
</style>
