<template>
  <footer class="gold-footer">
    <div class="gold-footer-balance" :style="GOLD_FOOTER_BALANCE_STYLES">
      Balance $
      <div class="gold-footer-balance-value">0.00</div>
    </div>
    <div class="gold-footer-content" :style="GOLD_FOOTER_CONTENT_STYLES">
      <div class="gold-footer-items">
        <gold-footer-game-item
            v-for="(game, index) in footer.games"
            :key="index"
            :img="game.src"
            :name="game.text"
        ></gold-footer-game-item>
      </div>
    </div>
  </footer>
</template>

<script>
import GoldFooterGameItem from "@/views/components/vlt-templates/Gold/vertical/common/footer/GoldFooterGameItem.vue";
import {ref} from "@vue/composition-api";


export default {
  name: "GoldFooter",
  components: {
    GoldFooterGameItem,
  },
  props: {
    footer: {
      type: Object,
    },
  },

  setup() {
    const GOLD_FOOTER_CONTENT_STYLES = ref({
      'background-image': 'var(--footer-bg-image)',
      'background-position': 'bottom',
      'background-size': '95% 60% !important',
      'background-repeat': 'no-repeat',
      'min-width': '30%'
    })

    const GOLD_FOOTER_BALANCE_STYLES = ref({
      'border': '2px solid var(--border_color)',
      'background': 'linear-gradient(135deg, var(--gradient_1_c) 0%, var(--gradient_2_c) 50%, var(--gradient_3_c) 51%, var(--gradient_4_c) 100% )',
      'border-radius': '20px',
      'padding': '0 15px',
      'text-align': 'center'
    })

    return {
      GOLD_FOOTER_CONTENT_STYLES,
      GOLD_FOOTER_BALANCE_STYLES,
    }

  }
};
</script>

<style scoped lang="scss">
.gold-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;

  &-balance {
    background: linear-gradient(
            135deg,
            var(--gradient_1_c) 0%,
            var(--gradient_2_c) 50%,
            var(--gradient_3_c) 51%,
            var(--gradient_4_c) 100%
    );
    border: 2px solid var(--border_color);
    height: 6vh;
    border-radius: 20px;
    padding: 0 15px;
    width: 30%;
    color: var(--color);
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;

    &-value {
      font-size: 30px;
    }
  }

  &-content {
    background-image: var(--footer-bg-image);
    background-position: bottom;
    background-size: 95% 60% !important;
    height: 10vh;
  }

  &-items {
    display: flex;
    gap: 50px;
  }

}
</style>
