export const HEADER = {
    vertical: {
        type:'vertical',
        name: '',
        jackpot: {
            href: "",
        },
        rightSection: {
            soundButton: {
                href: '',
                text: '',
                big: false,
            },
            languageButton: {
                href: '',
                text: '',
                big: false,
            },
            historyButton: {
                href: "",
                text: '',
                big: true
            },
            withdrawButton: {
                href: "",
                text: '',
                big: true
            }
        },
    },

    horizontal: {
        type:'horizontal',
        name: '',
        jackpot: {
            href: "",
        },
        rightSection: {
            soundButton: {
                href: ''
            },
            languageButton: {
                href: ''
            },
        },
        leftSection: {
            historyButton: {
                href: "",
                text: ''
            },
            withdrawButton: {
                href: "",
                text: ''
            }
        },
    }
}